import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminApiService } from '../admin-api.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ChangeShipComponent } from '../change-ship/change-ship.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  modalRef: BsModalRef;

  public updateUserForm: FormGroup;

  public setPasswordUserForm: FormGroup;

  public blockForm: FormGroup;

  public user_id = undefined

  public user = undefined

  public showDeleteDiv = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private AdminApi: AdminApiService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private toastr: ToasterService
  ) { }

  ngOnInit() {
    this.user_id = this.route.snapshot.paramMap['params']['user_id']
    this.loadUserInfo()
  }

  loadUserInfo() {
    this.AdminApi.getUserInfo(this.user_id).subscribe(response => {
      this.user = response
      this.loadForms()
    })
  }

  loadForms() {
    this.updateUserForm = this.formBuilder.group({
      full_name: [this.user.full_name, Validators.required],
      login: [this.user.login, Validators.required],
    });

    this.setPasswordUserForm = this.formBuilder.group({
      password: ['', Validators.required]
    });

    this.blockForm = this.formBuilder.group({
      block_date: [this.user.block_date, Validators.required]
    });
  }

  changeShip() {
    const initialState = {
      user_id: this.user_id
    };
    this.modalRef = this.modalService.show(ChangeShipComponent, { initialState, class: 'modal-lg' });
    this.modalRef.content.onChange.subscribe(response => {
      if (response) {
        this.loadUserInfo();
      }
    })
  }

  updateUser() {
    if (this.updateUserForm.invalid) {
      this.toastr.pop('error', 'Login or full name is empty');
    } else {
      const json_data = {
        user_id: this.user_id,
        full_name: this.updateUserForm.value['full_name'],
        login: this.updateUserForm.value['login']
      }
      this.AdminApi.updateUser(json_data).subscribe(response => {
        if (response.status == 'success') {
          this.toastr.pop('success', 'The user has been updated');
          this.loadUserInfo();
        } else {
          this.toastr.pop('error', 'The user has not been updated');
        }
      })
    }
  }

  setPassword() {
    if (this.setPasswordUserForm.invalid) {
      this.toastr.pop('error', 'The password is empty');
    } else {
      const json_data = {
        user_id: this.user_id,
        password: Md5.hashStr(this.user.login + this.setPasswordUserForm.value['password']),
      }
      this.AdminApi.setPassword(json_data).subscribe(response => {
        if (response.status == 'success') {
          this.toastr.pop('success', 'The password has been updated');
          this.loadUserInfo();
        } else {
          this.toastr.pop('error', 'The password has not been updated');
        }
      })
    }
  }

  showDeleteAllert() {
    this.showDeleteDiv = true;
  }

  hideDeleteAllert() {
    this.showDeleteDiv = false;
  }

  deleteUser() {
  const json_list = { 
    user_pkey: this.user_id
  }
  this.AdminApi.deleteUser(json_list).subscribe(response => {
    if (response.status == 'success'){
      this.router.navigate(['/modules/admin']);
    }
  })
  }

  timeBlockUser() {
    if (this.blockForm.invalid) {
      this.toastr.pop('error', 'The lock date is empty');
    } else {
      const json_data = {
        user_id: this.user_id,
        block_date: this.blockForm.value['block_date']
      }
      this.AdminApi.addUserBlockDate(json_data).subscribe(response => {
        if (response.status == 'success') {
          this.toastr.pop('success', 'The lock date has been updated');
          this.loadUserInfo();
        } else {
          this.toastr.pop('error', 'The lock date has not been updated');
        }
      })
    }
  }

  deleteBlockDate() {
    const json_data = {
      user_id: this.user_id,
      block_date: null
    }
    this.AdminApi.addUserBlockDate(json_data).subscribe(response => {
      if (response.status == 'success') {
        this.toastr.pop('success', 'The lock date has been deleted');
        this.loadUserInfo();
      } else {
        this.toastr.pop('error', 'The lock date has not been deleted');
      }
    })
  }

}

import { Component, OnInit, HostListener } from '@angular/core';
import { ToasterConfig } from 'angular2-toaster';

@Component({
  selector: 'naviserv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  /**
 * Const breakpoint value
 */
  public readonly BREAKPOINT = 992;

  /**
  * Variable determines if window is mobile size
  */
  public _mobile = false;

  constructor() {
  }

  /**
 * Scroll listener for window resize
 */
  @HostListener('window:resize')
  onResize() {
    this._mobile = window.innerWidth <= this.BREAKPOINT;
  }

  /**
* On init sets mobile variable and login Form
*/
  ngOnInit() {
    this._mobile = ((this.getWindowWidth() < this.BREAKPOINT) ? true : false);
  }

  /**
 * Returns window's width
 */
  private getWindowWidth(): number {
    return window.innerWidth;
  }

}
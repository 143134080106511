import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { AUTH } from '../auth/auth.models'
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserIdleService } from 'angular-user-idle';
import { ToasterService } from 'angular2-toaster';


@Component({
  selector: 'app-pages-component',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit{
  
  public isAdmin = false;

  constructor(
    private http: AuthService,
    private router: Router,
    private userIdle: UserIdleService,
    private toastr: ToasterService
  ) {
  }
    /** Subscription for IDLE  */
    ping: Subscription;

  ngOnInit() {
      this.checkAdmin()
  }

  logout(){
    this.http.logout().subscribe( response =>{
      if (response){
        localStorage.removeItem(AUTH);
        localStorage.clear()
        this.router.navigate(['login']);
      }

    })
  };

  setIdleService(){
    this.userIdle.startWatching();
    this.ping = this.userIdle.ping$.subscribe(() => {
      this.http.isAuthorizedNoExt().subscribe(response => {
        if (response.signed_in !== 'true') {
          this.toastr.pop('warning', 'You have been signed out due to inactivity');
          this.logout();
        }
      });
    });
  }

  checkAdmin(){
    this.http.isAdmin().subscribe(response =>{
      this.isAdmin = response.isadmin
    })
     
  }


}
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard'
import { LoginGuard } from './auth/login.guard'


const routes: Routes = [
  { path: 'login',
    component: LoginComponent,
   canActivate: [LoginGuard] },
  { path: '',
    redirectTo: '/modules/cylinder-balance',
    pathMatch: 'full',
    canActivate: [AuthGuard]},
];


@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartType, ChartOptions, Chart } from "chart.js"
import { Subscription } from 'rxjs';
import { NumericReferenceApiService } from './numeric-reference-api.service';
import { PagesService } from '../pages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service'


@Component({
  selector: 'app-numeric-reference',
  templateUrl: './numeric-reference.component.html',
  styleUrls: ['./numeric-reference.component.css']
})
export class NumericReferenceComponent implements OnInit {

  public isAdmin = undefined;
  public chartReady;
  public NumericReferenceListSubs: Subscription;
  public chartDataSet: ChartDataSets[];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    tooltips: {},
    title: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Load",
          },
          type: "linear",
          position: "bottom",
          ticks: {
            beginAtZero: true,
          }
        }
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Y",
          },
          ticks: {
            beginAtZero: true
          }
        }
      ]
    },
    maintainAspectRatio: false,
    aspectRatio: 1,
  };

  public lineChartType: ChartType = "line";
  public owner_list = [];
  public selectedOwner = undefined;
  public ship_list = []
  public selectedShip = undefined;
  public raport_list = [];
  public selectedRaportNumber = undefined
  public machinery_list = [];
  public selectedMachinery = undefined
  public task_list = []
  public selectedTask = undefined
  public point_list = []
  public selectedPoint = undefined


  public showPoint = true;

  public loadChart = true;

  constructor(
    private NumericReferenceApi: NumericReferenceApiService,
    private http: PagesService,
    private AuthServiceApi: AuthService,
    private route: ActivatedRoute,
    private router: Router,

  ) { }

  ngOnInit() {

    this.checkAdmin()
  }

  loadOwnerList() {
    if (localStorage.owner_list === undefined) {
      this.http.getOwnersList().subscribe(response => {
        for (let item of response.owner_list) {
          this.owner_list.push({ 'owner_id': item[0], 'owner_name': item[1] })
        }
        localStorage.setItem('owner_list', JSON.stringify(this.owner_list));
        this.selectedOwner = this.owner_list[0]
        localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
        this.loadAdminShipList()
      })
    } else {
      this.owner_list = JSON.parse(localStorage.owner_list)
      if (localStorage.owner === undefined) {
        this.selectedOwner = this.owner_list[0];
        localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
      } else {
        this.selectedOwner = JSON.parse(localStorage.owner)
      }
      this.loadAdminShipList()
    }
  }

  loadAdminShipList() {
    if (localStorage.ship_list === undefined) {
      this.http.getAdminShipsList(this.selectedOwner.owner_id).subscribe(response => {
        for (let item of response.ship_list) {
          this.ship_list.push({ 'ship_id': item[0], 'ship_name': item[1] })
        }
        localStorage.setItem('ship_list', JSON.stringify(this.ship_list));
        this.selectedShip = this.ship_list[0]
        localStorage.setItem('ship', JSON.stringify(this.selectedShip));
        if (this.ship_list.length > 0) {
          this.loadMachineryList()
        }
      })
    } else {
      this.ship_list = JSON.parse(localStorage.ship_list)
      if (localStorage.ship === undefined) {
        this.selectedShip = this.ship_list[0];
        localStorage.setItem('ship', JSON.stringify(this.selectedShip));
      } else {
        this.selectedShip = JSON.parse(localStorage.ship)
      }
      if (this.ship_list.length > 0) {
        this.loadMachineryList()
      }
    }
  }

  LoadUserShipList() {
    if (localStorage.ship_list === undefined) {
      this.http.getUserShipsList().subscribe(response => {
        for (let item of response.ship_list) {
          this.ship_list.push({ 'ship_id': item[0], 'ship_name': item[1] })
        }
        localStorage.setItem('ship_list', JSON.stringify(this.ship_list));
        this.selectedShip = this.ship_list[0]
        localStorage.setItem('ship', JSON.stringify(this.selectedShip));
        if (this.ship_list.length > 0) {
          this.loadMachineryList()
        }
      })
    } else {
      this.ship_list = JSON.parse(localStorage.ship_list)
      if (localStorage.ship === undefined) {
        this.selectedShip = this.ship_list[0];
        localStorage.setItem('ship', JSON.stringify(this.selectedShip));
      } else {
        this.selectedShip = JSON.parse(localStorage.ship)
      }
      if (this.ship_list.length > 0) {
        this.loadMachineryList()
      }
    }
  }

  loadMachineryList() {
    if (localStorage.machinery_list === undefined) {
      this.http.getMachineryList(this.selectedShip.ship_id).subscribe(response => {
        for (let item of response.device_list) {
          this.machinery_list.push({ 'device_id': item[0], 'device_name': item[1] })
        }
        localStorage.setItem('machinery_list', JSON.stringify(this.machinery_list));
        this.selectedMachinery = this.machinery_list[0];
        localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
        this.loadRaportList();
      })
    } else {
      this.machinery_list = JSON.parse(localStorage.machinery_list)
      if (localStorage.device === undefined) {
        this.selectedMachinery = this.machinery_list[0];
        localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
      } else {
        this.selectedMachinery = JSON.parse(localStorage.device)
      }
      this.loadRaportList();
    }
  }

  loadRaportList() {
    if (localStorage.raport_list === undefined) {
      this.http.getRaportList(this.selectedMachinery.device_id).subscribe(response => {
        for (let item of response.raport_list) {
          this.raport_list.push({ 'raport_number': item[0] })
        }
        localStorage.setItem('raport_list', JSON.stringify(this.raport_list));
        this.selectedRaportNumber = this.raport_list[0];
        localStorage.setItem('report_number', JSON.stringify(this.selectedRaportNumber));
        this.selectTaskList()
      })
    } else {
      this.raport_list = JSON.parse(localStorage.raport_list)
      if (localStorage.report_number === undefined) {
        this.selectedRaportNumber = this.raport_list[0];
        localStorage.setItem('report_number', JSON.stringify(this.selectedRaportNumber));
      } else {
        this.selectedRaportNumber = JSON.parse(localStorage.report_number)
      }
      this.selectTaskList()
    }
  }

  selectTaskList() {
    if (localStorage.pointCheck === undefined) {
      this.showPoint = true
      if (this.route.snapshot.paramMap['params']['device_id']) {
        if (this.route.snapshot.paramMap['params']['point_bool'] == 'false') {
          this.showPoint = false
        }
      }
    } else {
      let pointCheck = localStorage.pointCheck
      this.showPoint = (pointCheck == 'true');
    }
    if (this.showPoint) {
      this.loadPointTaskList()
    } else {
      this.loadDeviceTaskList()
    }
  }

  loadPointTaskList() {
    if (localStorage.task_point_list === undefined) {
      this.http.getPointTaskList(this.selectedRaportNumber.raport_number, this.selectedMachinery.device_id).subscribe(response => {
        for (let item of response.task_list) {
          this.task_list.push({ 'task_id': item[0], 'task_name': item[1] })
        }
        if (this.task_list.length > 0) {
          localStorage.setItem('task_point_list', JSON.stringify(this.task_list));
          this.selectedTask = this.task_list[0];
          localStorage.setItem('task', JSON.stringify(this.selectedTask));
          this.loadPointList()
        }
      })
    } else {
      this.task_list = JSON.parse(localStorage.task_point_list)
      if (localStorage.task === undefined) {
        this.selectedTask = this.task_list[0];
        localStorage.setItem('task', JSON.stringify(this.selectedTask));
      } else {
        this.selectedTask = JSON.parse(localStorage.task)
      }
      this.loadPointList()
    }
  }

  loadDeviceTaskList() {
    if (localStorage.task_device_list === undefined) {
      this.http.getDeviceTaskList(this.selectedRaportNumber.raport_number, this.selectedMachinery.device_id).subscribe(response => {
        for (let item of response.task_list) {
          this.task_list.push({ 'task_id': item[0], 'task_name': item[1] })
        }
        if (this.task_list.length > 0) {
          if (this.route.snapshot.paramMap['params']['device_id']) {
            let task_id = this.route.snapshot.paramMap['params']['task_id']
            this.http.getDeviceTaskName(task_id).subscribe(reponse => {
              let task_name = reponse
              this.selectedTask = { 'task_id': task_id, 'task_name': task_name }
              this.loadNumericReference()
            })
          } else {
            localStorage.setItem('task_device_list', JSON.stringify(this.task_list));
            this.selectedTask = this.task_list[0];
            localStorage.setItem('task', JSON.stringify(this.selectedTask));
            this.loadNumericReference()
          }
        }
      })
    } else {
      this.task_list = JSON.parse(localStorage.task_device_list)
      if (localStorage.task === undefined) {
        this.selectedTask = this.task_list[0];
        localStorage.setItem('task', JSON.stringify(this.selectedTask));
      } else {
        this.selectedTask = JSON.parse(localStorage.task)
      }
      this.loadNumericReference()
    }

  }

  loadPointList() {
    if (localStorage.point_list === undefined) {
      this.http.getPointList(this.selectedRaportNumber.raport_number, this.selectedMachinery.device_id, this.selectedTask.task_id)
        .subscribe(response => {
          for (let item of response.point_list) {
            this.point_list.push({ 'point_id': item[0], 'point_name': item[1] })
          };
          localStorage.setItem('point_list', JSON.stringify(this.point_list));
          this.selectedPoint = this.point_list[0]
          localStorage.setItem('point', JSON.stringify(this.selectedPoint));
          this.loadNumericReference()
        });
    } else {
      this.point_list = JSON.parse(localStorage.point_list)
      if (this.route.snapshot.paramMap['params']['device_id'] && this.route.snapshot.paramMap['params']['task_id']) {
        var device_id = this.route.snapshot.paramMap['params']['device_id']
        var task_id = this.route.snapshot.paramMap['params']['task_id']
        var point_id = this.route.snapshot.paramMap['params']['point_id']
        this.showPoint = this.route.snapshot.paramMap['params']['point_bool']
        this.NumericReferenceApi.getAllName(device_id, task_id, point_id).subscribe(response => {
          var task_name = response.task_name
          var point_name = response.point_name

          this.selectedRaportNumber = { raport_number: this.route.snapshot.paramMap['params']['raport_number'] }
          this.selectedTask = { task_id: task_id, task_name: task_name }
          this.selectedPoint = { point_id: point_id, point_name: point_name }
          this.loadNumericReference()
        });
      } else {
        if (localStorage.point === undefined) {
          this.selectedPoint = this.point_list[0]
          localStorage.setItem('point', JSON.stringify(this.selectedPoint));
        } else {
          this.selectedPoint = JSON.parse(localStorage.point)
        }
        this.loadNumericReference()
      };
    }
  };


  loadNumericReference() {
    this.loadChart = true;
    if (!this.selectedPoint) {
      this.selectedPoint = { point_id: '0', point_name: 'NOPOINT' }
    }
    this.NumericReferenceListSubs = this.NumericReferenceApi.getNumericReference(this.selectedMachinery.device_id, this.selectedRaportNumber.raport_number, this.selectedTask.task_id, this.selectedPoint.point_id, this.showPoint)
      .subscribe(response => {
        if (response.data.length > 0) {
          this.lineChartOptions.scales.xAxes[0].ticks['max'] = response.data[2].data[response.data[2].data.length - 1].x
          this.lineChartOptions.scales.yAxes[0].scaleLabel['labelString'] = response.unit
        }
        this.lineChartOptions['legend'] = {
          display: true,
          labels: {
            filter: function (item, chart) {
              return !item.text.includes('_');
            }
          },
          onClick: function (e, legendItem) {
            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
            var meta = ci.getDatasetMeta(index);

            var meta_hi = ci.getDatasetMeta(index + 1);
            if (!alreadyHidden) {
              meta.hidden = true;
              if (index == 2) {
                meta_hi.hidden = true;
              }
            } else {
              meta.hidden = null;
              if (index == 2) {
                meta_hi.hidden = null;
              }
            }

            ci.update();
          },
          position: "top",
        }
        
        this.chartDataSet = response.data
        this.loadChart = false;
      })
  }

  onChangeOwner() {
    localStorage.removeItem('owner')
    localStorage.removeItem('ship')
    localStorage.removeItem('device')
    localStorage.removeItem('report_number')
    localStorage.removeItem('task')
    localStorage.removeItem('point')
    localStorage.removeItem('ship_list')
    localStorage.removeItem('task_point_list')
    localStorage.removeItem('point_list')
    localStorage.removeItem('raport_list')
    localStorage.removeItem('machinery_list')
    localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
    this.machinery_list = []
    this.raport_list = []
    this.task_list = []
    this.ship_list = []
    this.loadAdminShipList()
  }

  onChangeShip() {
    localStorage.removeItem('ship')
    localStorage.removeItem('device')
    localStorage.removeItem('report_number')
    localStorage.removeItem('task')
    localStorage.removeItem('point')
    localStorage.removeItem('task_point_list')
    localStorage.removeItem('point_list')
    localStorage.removeItem('raport_list')
    localStorage.removeItem('machinery_list')
    localStorage.setItem('ship', JSON.stringify(this.selectedShip));
    this.machinery_list = []
    this.raport_list = []
    this.task_list = []
    this.loadMachineryList()
  }

  onChangeMachinery() {
    localStorage.removeItem('device')
    localStorage.removeItem('report_number')
    localStorage.removeItem('task')
    localStorage.removeItem('point')
    localStorage.removeItem('task_point_list')
    localStorage.removeItem('task_device_list')
    localStorage.removeItem('point_list')
    localStorage.removeItem('raport_list')
    localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
    this.raport_list = []
    this.task_list = []
    this.point_list = []
    this.loadRaportList()
  }

  onChangeRaport() {
    localStorage.removeItem('report_number')
    localStorage.removeItem('task')
    localStorage.removeItem('point')
    localStorage.removeItem('task_point_list')
    localStorage.removeItem('task_device_list')
    localStorage.removeItem('point_list')
    localStorage.setItem('report_number', JSON.stringify(this.selectedRaportNumber));
    this.task_list = []
    this.point_list = []
    this.selectTaskList()
  }

  onChangeTask() {
    localStorage.removeItem('task')
    localStorage.removeItem('point')
    localStorage.removeItem('point_list')
    localStorage.setItem('task', JSON.stringify(this.selectedTask));
    this.point_list = []
    if (this.showPoint) {
      this.loadPointList()
    } else {
      this.loadNumericReference()
    }

  }

  onChangePoint() {
    localStorage.removeItem('point')
    localStorage.setItem('point', JSON.stringify(this.selectedPoint));
    this.loadNumericReference()
  }

  checkPoint() {
    localStorage.removeItem('task')
    localStorage.removeItem('point')
    localStorage.removeItem('task_point_list')
    localStorage.removeItem('task_device_list')
    localStorage.removeItem('point_list')
    localStorage.removeItem('pointCheck')
    this.task_list = []
    this.point_list = []
    if (this.showPoint) {
      this.showPoint = false

    } else {
      this.showPoint = true
    }
    localStorage.setItem('pointCheck', String(this.showPoint));
    this.selectTaskList()
  }

  clickElement(e) {
    if (e.active.length > 0) {
      if (e.active[0]._datasetIndex == 0) {
        this.router.navigate(['/modules/cylinder-balance']);
      }
    }
  }

  checkAdmin() {
    this.AuthServiceApi.isAdmin().subscribe(response => {
      this.isAdmin = response.isadmin
      if (this.isAdmin) {
        this.loadOwnerList()
      } else {
        this.LoadUserShipList()
      }
    })

  }
  //
  // loadOwnerList() {
  //   if (localStorage.owner_list === undefined) {
  //     this.http.getOwnersList().subscribe(response => {
  //       for (let item of response.owner_list) {
  //         this.owner_list.push({ 'owner_id': item[0], 'owner_name': item[1] })
  //       }
  //       localStorage.setItem('owner_list', JSON.stringify(this.owner_list));
  //       this.selectedOwner = this.owner_list[0]
  //       localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
  //       this.loadAdminShipList()
  //     })
  //   }else{
  //     this.owner_list = JSON.parse(localStorage.owner_list)
  //     if (localStorage.owner === undefined) {
  //       this.selectedOwner = this.owner_list[0];
  //       localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
  //     } else {
  //       this.selectedOwner = JSON.parse(localStorage.owner)
  //     }
  //     this.loadAdminShipList()
  //   }
  // }
  //
  // loadAdminShipList() {
  //   if (localStorage.ship_list === undefined) {
  //     this.http.getAdminShipsList(this.selectedOwner.owner_id).subscribe(response => {
  //       for (let item of response.ship_list) {
  //         this.ship_list.push({ 'ship_id': item[0], 'ship_name': item[1] })
  //       }
  //       localStorage.setItem('ship_list', JSON.stringify(this.ship_list));
  //       this.selectedShip = this.ship_list[0]
  //       localStorage.setItem('ship', JSON.stringify(this.selectedShip));
  //       if (this.ship_list.length > 0) {
  //         this.loadMachineryList()
  //       }
  //     })
  //   } else {
  //     this.ship_list = JSON.parse(localStorage.ship_list)
  //     if (localStorage.ship === undefined) {
  //       this.selectedShip = this.ship_list[0];
  //       localStorage.setItem('ship', JSON.stringify(this.selectedShip));
  //     } else {
  //       this.selectedShip = JSON.parse(localStorage.ship)
  //     }
  //     if (this.ship_list.length > 0) {
  //       this.loadMachineryList()
  //     }
  //   }
  // }
  //
  // LoadUserShipList() {
  //   if (localStorage.ship_list === undefined) {
  //     this.http.getUserShipsList().subscribe(response => {
  //       for (let item of response.ship_list) {
  //         this.ship_list.push({ 'ship_id': item[0], 'ship_name': item[1] })
  //       }
  //       localStorage.setItem('ship_list', JSON.stringify(this.ship_list));
  //       this.selectedShip = this.ship_list[0]
  //       localStorage.setItem('ship', JSON.stringify(this.selectedShip));
  //       if (this.ship_list.length > 0) {
  //         this.loadMachineryList()
  //       }
  //     })
  //   } else {
  //     this.ship_list = JSON.parse(localStorage.ship_list)
  //     if (localStorage.ship === undefined) {
  //       this.selectedShip = this.ship_list[0];
  //       localStorage.setItem('ship', JSON.stringify(this.selectedShip));
  //     } else {
  //       this.selectedShip = JSON.parse(localStorage.ship)
  //     }
  //     if (this.ship_list.length > 0) {
  //       this.loadMachineryList()
  //     }
  //   }
  // }
  //
  // loadMachineryList() {
  //   if (localStorage.machinery_list === undefined) {
  //     this.http.getMachineryList(this.selectedShip.ship_id).subscribe(response => {
  //       for (let item of response.device_list) {
  //         this.machinery_list.push({ 'device_id': item[0], 'device_name': item[1] })
  //       }
  //       localStorage.setItem('machinery_list', JSON.stringify(this.machinery_list));
  //       this.selectedMachinery = this.machinery_list[0];
  //       localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
  //       this.loadRaportList();
  //     })
  //   } else {
  //     this.machinery_list = JSON.parse(localStorage.machinery_list)
  //     if (localStorage.device === undefined) {
  //       this.selectedMachinery = this.machinery_list[0];
  //       localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
  //     } else {
  //       this.selectedMachinery = JSON.parse(localStorage.device)
  //     }
  //     this.loadRaportList();
  //   }
  // }
  //
  // loadRaportList() {
  //   if (localStorage.raport_list === undefined) {
  //     this.http.getRaportList(this.selectedMachinery.device_id).subscribe(response => {
  //       for (let item of response.raport_list) {
  //         this.raport_list.push({ 'raport_number': item[0] })
  //       }
  //       localStorage.setItem('raport_list', JSON.stringify(this.raport_list));
  //       this.selectedRaportNumber = this.raport_list[0];
  //       localStorage.setItem('report_number', JSON.stringify(this.selectedRaportNumber));
  //       this.selectTaskList()
  //     })
  //   } else {
  //     this.raport_list = JSON.parse(localStorage.raport_list)
  //     if (localStorage.report_number === undefined) {
  //       this.selectedRaportNumber = this.raport_list[0];
  //       localStorage.setItem('report_number', JSON.stringify(this.selectedRaportNumber));
  //     } else {
  //       this.selectedRaportNumber = JSON.parse(localStorage.report_number)
  //     }
  //     this.selectTaskList()
  //   }
  // }
  //
  // selectTaskList() {
  //   if (localStorage.pointCheck === undefined) {
  //     this.showPoint = true
  //     if (this.route.snapshot.paramMap['params']['device_id']) {
  //       if (this.route.snapshot.paramMap['params']['point_bool'] == 'false') {
  //         this.showPoint = false
  //       }
  //     }
  //   } else {
  //     let pointCheck = localStorage.pointCheck
  //     this.showPoint = (pointCheck == 'true');
  //   }
  //   if (this.showPoint) {
  //     this.loadPointTaskList()
  //   } else {
  //     this.loadDeviceTaskList()
  //   }
  // }
  //
  // loadPointTaskList() {
  //   if (localStorage.task_point_list === undefined) {
  //     this.http.getPointTaskList(this.selectedRaportNumber.raport_number, this.selectedMachinery.device_id).subscribe(response => {
  //       for (let item of response.task_list) {
  //         this.task_list.push({ 'task_id': item[0], 'task_name': item[1] })
  //       }
  //       if (this.task_list.length > 0) {
  //         localStorage.setItem('task_point_list', JSON.stringify(this.task_list));
  //         this.selectedTask = this.task_list[0];
  //         localStorage.setItem('task', JSON.stringify(this.selectedTask));
  //         this.loadPointList()
  //       }
  //     })
  //   } else {
  //     this.task_list = JSON.parse(localStorage.task_point_list)
  //     if (localStorage.task === undefined) {
  //       this.selectedTask = this.task_list[0];
  //       localStorage.setItem('task', JSON.stringify(this.selectedTask));
  //     } else {
  //       this.selectedTask = JSON.parse(localStorage.task)
  //     }
  //     this.loadPointList()
  //   }
  // }
  //
  // loadDeviceTaskList() {
  //   if (localStorage.task_device_list === undefined) {
  //     this.http.getDeviceTaskList(this.selectedRaportNumber.raport_number, this.selectedMachinery.device_id).subscribe(response => {
  //       for (let item of response.task_list) {
  //         this.task_list.push({ 'task_id': item[0], 'task_name': item[1] })
  //       }
  //       if (this.task_list.length > 0) {
  //         if (this.route.snapshot.paramMap['params']['device_id']) {
  //           let task_id = this.route.snapshot.paramMap['params']['task_id']
  //           this.http.getDeviceTaskName(task_id).subscribe(reponse => {
  //             let task_name = reponse
  //             this.selectedTask = { 'task_id': task_id, 'task_name': task_name }
  //             this.loadNumericReference()
  //           })
  //         } else {
  //           localStorage.setItem('task_device_list', JSON.stringify(this.task_list));
  //           this.selectedTask = this.task_list[0];
  //           localStorage.setItem('task', JSON.stringify(this.selectedTask));
  //           this.loadNumericReference()
  //         }
  //       }
  //     })
  //   } else {
  //     this.task_list = JSON.parse(localStorage.task_device_list)
  //     if (localStorage.task === undefined) {
  //       this.selectedTask = this.task_list[0];
  //       localStorage.setItem('task', JSON.stringify(this.selectedTask));
  //     } else {
  //       this.selectedTask = JSON.parse(localStorage.task)
  //     }
  //     this.loadNumericReference()
  //   }
  //
  // }
  //
  // loadPointList() {
  //   if (localStorage.point_list === undefined) {
  //     this.http.getPointList(this.selectedRaportNumber.raport_number, this.selectedMachinery.device_id, this.selectedTask.task_id)
  //       .subscribe(response => {
  //         for (let item of response.point_list) {
  //           this.point_list.push({ 'point_id': item[0], 'point_name': item[1] })
  //         };
  //         localStorage.setItem('point_list', JSON.stringify(this.point_list));
  //         this.selectedPoint = this.point_list[0]
  //         localStorage.setItem('point', JSON.stringify(this.selectedPoint));
  //         this.loadNumericReference()
  //       });
  //   } else {
  //     this.point_list = JSON.parse(localStorage.point_list)
  //     if (this.route.snapshot.paramMap['params']['device_id'] && this.route.snapshot.paramMap['params']['task_id']) {
  //       var device_id = this.route.snapshot.paramMap['params']['device_id']
  //       var task_id = this.route.snapshot.paramMap['params']['task_id']
  //       var point_id = this.route.snapshot.paramMap['params']['point_id']
  //       this.showPoint = this.route.snapshot.paramMap['params']['point_bool']
  //       this.NumericReferenceApi.getAllName(device_id, task_id, point_id).subscribe(response => {
  //         var device_name = response.device_name
  //         var task_name = response.task_name
  //         var point_name = response.point_name
  //
  //         this.selectedRaportNumber = { raport_number: this.route.snapshot.paramMap['params']['raport_number'] }
  //         this.selectedMachinery = { device_id: device_id, device_name: device_name }
  //         this.selectedTask = { task_id: task_id, task_name: task_name }
  //         this.selectedPoint = { point_id: point_id, point_name: point_name }
  //         this.loadNumericReference()
  //       });
  //     } else {
  //       if (localStorage.point === undefined) {
  //         this.selectedPoint = this.point_list[0]
  //         localStorage.setItem('point', JSON.stringify(this.selectedPoint));
  //       } else {
  //         this.selectedPoint = JSON.parse(localStorage.point)
  //       }
  //       this.loadNumericReference()
  //     };
  //   }
  // };
  //
  //
  // loadNumericReference() {
  //   if (!this.selectedPoint) {
  //     this.selectedPoint = { point_id: '0', point_name: 'NOPOINT' }
  //   }
  //   this.NumericReferenceListSubs = this.NumericReferenceApi.getNumericReference(this.selectedMachinery.device_id, this.selectedRaportNumber.raport_number, this.selectedTask.task_id, this.selectedPoint.point_id, this.showPoint)
  //     .subscribe(response => {
  //       this.chartDataSet = response
  //     })
  // }
  //
  // onChangeOwner() {
  //   localStorage.removeItem('owner')
  //   localStorage.removeItem('ship')
  //   localStorage.removeItem('device')
  //   localStorage.removeItem('report_number')
  //   localStorage.removeItem('task')
  //   localStorage.removeItem('point')
  //   localStorage.removeItem('ship_list')
  //   localStorage.removeItem('task_point_list')
  //   localStorage.removeItem('point_list')
  //   localStorage.removeItem('raport_list')
  //   localStorage.removeItem('machinery_list')
  //   localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
  //   this.machinery_list = []
  //   this.raport_list = []
  //   this.task_list = []
  //   this.ship_list = []
  //   this.loadAdminShipList()
  // }
  //
  // onChangeShip() {
  //   localStorage.removeItem('ship')
  //   localStorage.removeItem('device')
  //   localStorage.removeItem('report_number')
  //   localStorage.removeItem('task')
  //   localStorage.removeItem('point')
  //   localStorage.removeItem('task_point_list')
  //   localStorage.removeItem('point_list')
  //   localStorage.removeItem('raport_list')
  //   localStorage.removeItem('machinery_list')
  //   localStorage.setItem('ship', JSON.stringify(this.selectedShip));
  //   this.machinery_list = []
  //   this.raport_list = []
  //   this.task_list = []
  //   this.loadMachineryList()
  // }
  //
  // onChangeMachinery() {
  //   localStorage.removeItem('device')
  //   localStorage.removeItem('report_number')
  //   localStorage.removeItem('task')
  //   localStorage.removeItem('point')
  //   localStorage.removeItem('task_point_list')
  //   localStorage.removeItem('task_device_list')
  //   localStorage.removeItem('point_list')
  //   localStorage.removeItem('raport_list')
  //   localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
  //   this.raport_list = []
  //   this.task_list = []
  //   this.point_list = []
  //   this.loadRaportList()
  // }
  //
  // onChangeRaport() {
  //   localStorage.removeItem('report_number')
  //   localStorage.removeItem('task')
  //   localStorage.removeItem('point')
  //   localStorage.removeItem('task_point_list')
  //   localStorage.removeItem('task_device_list')
  //   localStorage.removeItem('point_list')
  //   localStorage.setItem('report_number', JSON.stringify(this.selectedRaportNumber));
  //   this.task_list = []
  //   this.point_list = []
  //   this.selectTaskList()
  // }
  //
  // onChangeTask() {
  //   localStorage.removeItem('task')
  //   localStorage.removeItem('point')
  //   localStorage.removeItem('point_list')
  //   localStorage.setItem('task', JSON.stringify(this.selectedTask));
  //   this.point_list = []
  //   if (this.showPoint) {
  //     this.loadPointList()
  //   } else {
  //     this.loadNumericReference()
  //   }
  //
  // }
  //
  // onChangePoint() {
  //   localStorage.removeItem('point')
  //   localStorage.setItem('point', JSON.stringify(this.selectedPoint));
  //   this.loadNumericReference()
  // }
  //
  // checkPoint() {
  //   localStorage.removeItem('task')
  //   localStorage.removeItem('point')
  //   localStorage.removeItem('task_point_list')
  //   localStorage.removeItem('task_device_list')
  //   localStorage.removeItem('point_list')
  //   localStorage.removeItem('pointCheck')
  //   this.task_list = []
  //   this.point_list = []
  //   if (this.showPoint) {
  //     this.showPoint = false
  //
  //   } else {
  //     this.showPoint = true
  //   }
  //   localStorage.setItem('pointCheck', String(this.showPoint));
  //   this.selectTaskList()
  // }
  //
  // clickElement(e) {
  //   if (e.active.length > 0) {
  //     if (e.active[0]._datasetIndex == 0) {
  //       this.router.navigate(['/modules/cylinder-balance']);
  //     }
  //   }
  // }
  //
  // checkAdmin() {
  //   this.AuthServiceApi.isAdmin().subscribe(response => {
  //     this.isAdmin = response.isadmin
  //     if (this.isAdmin) {
  //       this.loadOwnerList()
  //     } else {
  //       this.LoadUserShipList()
  //     }
  //   })
  // }
}

import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChartDataSets, ChartType, ChartOptions } from "chart.js";
import { ChartDataApiService } from './chart-data-api.service';
import { PagesService } from '../pages.service';
import { AuthService } from '../../auth/auth.service'
import { Router, ActivatedRoute } from '@angular/router';
import { CylinderBalanceApiService } from '../cylinder-balance/cylinder-balance-api.service';
import * as ChartAnnotation from 'chartjs-plugin-annotation';

@Component({
    selector: 'app-chart-data',
    templateUrl: './chart-data.component.html',
    styleUrls: ['./chart-data.component.css']
})
export class ChartDataComponent implements OnInit {
    title;
    CylinderBalanceListSubs: Subscription;
    resultjson;
    barChartData;
    barChartLabels;
    barChartOptions;
    public isAdmin = undefined
    public chartReady;
    public chartReadyy;
    public chartMainBearings;
    public NumericReferenceListSubs: Subscription;
    public chartDataSet: ChartDataSets[];
    chartDataSets: { key: string, data: ChartDataSets[], options: ChartOptions }[] = [];
    public selectedPointToSub = undefined;
    public lineChartOptions: ChartOptions;
    public lineChartType: ChartType = "line";
    public exampleTable = undefined;
    public pointsList = [];
    public camshaftChartKeys = [];
    public vallist = [];
    public camshaftValList = [];
    public parametersList = [];
    public owner_list = [];
    public selectedOwner = undefined;
    public showparameters = false;
    public ship_list = []
    public selectedShip = undefined;
    public raport_list = [];
    public selectedRaportNumber = undefined
    public machinery_list = [];
    public selectedMachinery = undefined
    public selectedChartType = undefined

    public task_list = []
    public selectedTask = undefined
    public point_list = []
    public selectedPoint = undefined
    public modifiedPoint = undefined
    public table_list = []
    router: any;
    public chartMainBearingsReady = undefined;



    constructor(
        private CylinderBalanceApi: CylinderBalanceApiService,
        private NumericReferenceApi: ChartDataApiService,
        private AuthServiceApi: AuthService,
        private http: PagesService,
        private route: ActivatedRoute


    ) { }
    public barChartType = 'bar';
    public barChartLegend = false;
    public colorlist;
    public name;
    public labels;


    ngOnInit() {
        this.chartReady = false
        this.chartReadyy = false

        this.checkAdmin()
    }
  ngOnDestroy() {
    if (this.CylinderBalanceListSubs) {
      this.CylinderBalanceListSubs.unsubscribe();
    }
  }
    loadOwnerList() {
        if (localStorage.owner_list === undefined) {
            this.http.getOwnersList().subscribe(response => {
                for (let item of response.owner_list) {
                    this.owner_list.push({ 'owner_id': item[0], 'owner_name': item[1] })
                }
                localStorage.setItem('owner_list', JSON.stringify(this.owner_list));
                this.selectedOwner = this.owner_list[0]
                localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
                this.loadAdminShipList()
            })
        } else {
            this.owner_list = JSON.parse(localStorage.owner_list)
            if (localStorage.owner === undefined) {
                this.selectedOwner = this.owner_list[0];
                localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
            } else {
                this.selectedOwner = JSON.parse(localStorage.owner)
            }
            this.loadAdminShipList()
        }
    }

    loadAdminShipList() {
        if (localStorage.ship_list === undefined) {
            this.http.getAdminShipsList(this.selectedOwner.owner_id).subscribe(response => {
                for (let item of response.ship_list) {
                    this.ship_list.push({ 'ship_id': item[0], 'ship_name': item[1] })
                }
                localStorage.setItem('ship_list', JSON.stringify(this.ship_list));
                this.selectedShip = this.ship_list[0]
                localStorage.setItem('ship', JSON.stringify(this.selectedShip));
                if (this.ship_list.length > 0) {
                    this.loadMachineryList()
                }
            })
        } else {
            this.ship_list = JSON.parse(localStorage.ship_list)
            if (localStorage.ship === undefined) {
                this.selectedShip = this.ship_list[0];
                localStorage.setItem('ship', JSON.stringify(this.selectedShip));
            } else {
                this.selectedShip = JSON.parse(localStorage.ship)
            }
            if (this.ship_list.length > 0) {
                this.loadMachineryList()
            }
        }
    }

    LoadUserShipList() {
        if (localStorage.ship_list === undefined) {
            this.http.getUserShipsList().subscribe(response => {
                for (let item of response.ship_list) {
                    this.ship_list.push({ 'ship_id': item[0], 'ship_name': item[1] })
                }
                localStorage.setItem('ship_list', JSON.stringify(this.ship_list));
                this.selectedShip = this.ship_list[0]
                localStorage.setItem('ship', JSON.stringify(this.selectedShip));
                if (this.ship_list.length > 0) {
                    this.loadMachineryList()
                }
            })
        } else {
            this.ship_list = JSON.parse(localStorage.ship_list)
            if (localStorage.ship === undefined) {
                this.selectedShip = this.ship_list[0];
                localStorage.setItem('ship', JSON.stringify(this.selectedShip));
            } else {
                this.selectedShip = JSON.parse(localStorage.ship)
            }
            if (this.ship_list.length > 0) {
                this.loadMachineryList()
            }
        }
    }
    loadMachineryList() {
        if (localStorage.machinery_list === undefined) {
            this.http.getMachineryList(this.selectedShip.ship_id).subscribe(response => {
                for (let item of response.device_list) {
                    this.machinery_list.push({ 'device_id': item[0], 'device_name': item[1] })
                }
                localStorage.setItem('machinery_list', JSON.stringify(this.machinery_list));
                this.selectedMachinery = this.machinery_list[0];
                localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
                this.loadRaportList();
            })
        } else {
            this.machinery_list = JSON.parse(localStorage.machinery_list)
            if (localStorage.device === undefined) {
                this.selectedMachinery = this.machinery_list[0];
                localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
            } else {
                this.selectedMachinery = JSON.parse(localStorage.device)
            }
            this.loadRaportList();
        }
    }

    loadRaportList() {
        if (localStorage.raport_list === undefined) {
            this.http.getRaportList(this.selectedMachinery.device_id).subscribe(response => {
                for (let item of response.raport_list) {
                    this.raport_list.push({ 'raport_number': item[0] })
                }
                localStorage.setItem('raport_list', JSON.stringify(this.raport_list));
                this.selectedRaportNumber = this.raport_list[0];
                localStorage.setItem('report_number', JSON.stringify(this.selectedRaportNumber));
                this.loadPointList()
                this.loadTaskList()
            })
        } else {
            this.raport_list = JSON.parse(localStorage.raport_list)
            if (localStorage.report_number === undefined) {
                this.selectedRaportNumber = this.raport_list[0];
                localStorage.setItem('report_number', JSON.stringify(this.selectedRaportNumber));
            } else {
                this.selectedRaportNumber = JSON.parse(localStorage.report_number)
            }
            this.loadPointList()

            
        }
    }


  onChangeTask() {
    localStorage.removeItem('task')
    localStorage.removeItem('point_list')
    localStorage.setItem('task', JSON.stringify(this.selectedTask));
    if(this.selectedPoint.point_name == 'Main bearings') {
      this.loadChart()
    }



}

    loadPointList() {
      if ((localStorage.point_list === undefined) || !(localStorage.point_list.includes('bearings'))) {
        this.http.getList(this.selectedRaportNumber.raport_number, this.selectedMachinery.device_id)
                .subscribe(response => {
                    this.point_list = []
                    for (let item of response.point_list) {
                        this.point_list.push({ 'point_id': item[0], 'point_name': item[1] })
                    };
                    localStorage.setItem('point_list', JSON.stringify(this.point_list));
                    this.selectedPoint = JSON.parse(localStorage.point)
                    localStorage.setItem('point', JSON.stringify(this.selectedPoint));
                    if (this.selectedPoint.point_name == 'Main bearings') {
                      this.loadChart()
                        this.loadTable()
                        this.chartReady = false
                        this.chartReadyy = false
                        
                    } else if (this.selectedPoint.point_name == 'Camshaft bearings') {
                        this.chartReady = false
                        this.chartReadyy = true
                        this.loadChart()
                        this.parametersList = [];
                        this.vallist = []
                    }
                
                    else {
                        this.exampleTable = undefined;
                        this.pointsList = [];
                        this.parametersList = [];
                        this.loadChart()
                        this.vallist = [];
                        this.parametersList = [];

                    }
                });
        } else {
            this.point_list = JSON.parse(localStorage.point_list)
            if (localStorage.point === undefined) {
                this.selectedPoint = this.point_list[0]
                localStorage.setItem('point', JSON.stringify(this.selectedPoint));
            } else {
                this.selectedPoint = JSON.parse(localStorage.point)
            }
            if (this.selectedPoint.point_name == 'Main bearings') {
                this.loadTable()
                this.chartReady = false
                this.chartReadyy = false

            } else if (this.selectedPoint.point_name == 'Camshaft bearings') {
                this.chartReady = false
                this.chartReadyy = true
                this.loadChart()
                this.parametersList = [];
                this.vallist = [];
            }
        
            else {
                this.exampleTable = undefined;
                this.pointsList = [];
                this.parametersList = [];
                this.loadChart()
                this.vallist = []
                this.chartReady = false
                this.chartReadyy = false

            }
        };
    }

   

  loadChart() {
  this.chartReady = false;
  this.chartDataSets = [];
  if (this.selectedPoint.point_name == "Camshaft bearings") {
    this.selectedPointToSub = "cb"
   } else if (this.selectedPoint.point_name == "Main bearings") {
    this.selectedPointToSub = "mb"
   } else {
    this.selectedPointToSub = "un"
   }

  this.NumericReferenceListSubs = this.NumericReferenceApi.getChartData(this.selectedPoint.point_id, this.selectedRaportNumber.raport_number, this.selectedPointToSub)

    .subscribe(res => {
      const length = Object.keys(res).length;


      this.camshaftChartKeys = Object.keys(res);

      if (this.selectedPoint.point_name == "Camshaft bearings") {
        
        
        for (let i = 0; i < res.chart_data.length; i += 2) {
          const chartDataSet = res.chart_data[i];
          const backgroundDataSet = res.chart_data[i + 1];
      
          const modifiedPoint = this.selectedPoint.point_name + '_' + i; 
          const chartData = {
            key: modifiedPoint,
            data: [chartDataSet, backgroundDataSet],
            options: {
              responsive: true,
              aspectRatio: 2,
              maintainAspectRatio: false,
              ...res.chart_setup,
              legend: {
                display: true,
                labels: {
                  filter: function(item, chart) {
                    return !item.text.includes('_background'); 
                  }
                },
               
                position: "top"
              }
            }
          };
      
          this.chartDataSets.push(chartData);
        }
      
        this.chartReady = false;
        this.chartReadyy = true;
        this.chartMainBearings = false;
      
           
      
            } else  if (this.selectedPoint.point_name == "Main bearings") {
              for (let i = 0; i < res.chart_data.length; i += 2) {
                const chartDataSet = res.chart_data[i];
                const backgroundDataSet = res.chart_data[i + 1];
            
                const modifiedPoint = this.selectedPoint.point_name + '_' + i; 
                const chartData = {
                  key: modifiedPoint,
                  data: [chartDataSet, backgroundDataSet],
                  options: {
                    responsive: true,
                    aspectRatio: 2,
                    maintainAspectRatio: false,
                    ...res.chart_setup,
                    legend: {
                      display: true,
                      labels: {
                        filter: function(item, chart) {
                          return !item.text.includes('_background'); 
                        }
                      },
                     
                      position: "top"
                    }
                  }
                };
            
                this.chartDataSets.push(chartData);
              }
      
              this.chartReady = false;
              this.chartReadyy = false;
              this.chartMainBearings = true;
            }
            
            else {
            
                var modifiedPoint = this.selectedPoint.point_name.replace(/ /g, '')
              res.chart_setup['responsive'] = true
              res.chart_setup['title'] = {
                    display: true,
                    text: 'Bad Confidence Intervals'
                }
                res.chart_setup['legend'] = {
                    display: true,
                    labels: {
                        filter: function (item, chart) {
                            return !item.text.includes('_');
                        }
                    },
                    onClick: function (e, legendItem) {
                        var index = legendItem.datasetIndex;
                        var ci = this.chart;
                        var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden;
                        var meta = ci.getDatasetMeta(index);
                        var meta_hi = ci.getDatasetMeta(index + 1);
                        if (!alreadyHidden) {
                            meta.hidden = true;
                            meta_hi.hidden = true;
                        } else {
                            meta.hidden = null;
                            meta_hi.hidden = null;
                        }
                        ci.update();
                    },
                    position: "top",
                }
                this.chartDataSet = res.chart_data;
                this.lineChartOptions = res.chart_setup
                this.chartReady = true
                this.chartReadyy = false;
                this.chartMainBearings = false;

            }
          
          });
      }

    loadTaskList() {
        if (this.route.snapshot.paramMap['params']['device_id'] && this.route.snapshot.paramMap['params']['task_id']) {
          var device_id = this.route.snapshot.paramMap['params']['device_id']
          var task_id = this.route.snapshot.paramMap['params']['task_id']
          this.CylinderBalanceApi.getAllName(device_id, task_id).subscribe(response => {
            var device_name = response.device_name
            var task_name = response.task_name
    
            this.selectedRaportNumber = { raport_number: this.route.snapshot.paramMap['params']['raport_number'] }
            this.selectedMachinery = { device_id: device_id, device_name: device_name }
            this.selectedTask = { task_id: task_id, task_name: task_name }
            this.http.getPointTaskList(this.selectedRaportNumber.raport_number, this.selectedMachinery.device_id).subscribe(response => {
              for (let item of response.task_list) {
                this.task_list.push({ 'task_id': item[0], 'task_name': item[1] })
              }
              localStorage.setItem('task_point_list', JSON.stringify(this.task_list));
              localStorage.setItem('task', JSON.stringify(this.selectedTask));
 if ((this.task_list.length > 0) && (this.selectedPoint.point_name == 'Main bearings')) {
  this.loadChart()
              } else if (this.selectedPoint.point_name == 'Camshaft bearings') {
                this.loadChart()
                this.vallist = []
              } else {
                this.vallist = []
              }
                        });
          })
        } else {
          if (localStorage.task_point_list === undefined) {
            this.http.getPointTaskList(this.selectedRaportNumber.raport_number, this.selectedMachinery.device_id).subscribe(response => {
              for (let item of response.task_list) {
                this.task_list.push({ 'task_id': item[0], 'task_name': item[1] })
              }
              localStorage.setItem('task_point_list', JSON.stringify(this.task_list));
              this.selectedTask = this.task_list[0];
              localStorage.setItem('task', JSON.stringify(this.selectedTask));
              
              if ((this.task_list.length > 0) && (this.selectedPoint.point_name == 'Main bearings')) {
                this.loadChart()
                            } else if ((this.selectedPoint.point_name == 'Camshaft bearings')) {
                this.loadChart()
                this.vallist = []
              } else {
                this.vallist = []
              }
            })
          } else {
            this.task_list = JSON.parse(localStorage.task_point_list)
            if (localStorage.task === undefined) {
              this.selectedTask = this.task_list[0];
              localStorage.setItem('task', JSON.stringify(this.selectedTask));
            } else {
              this.selectedTask = JSON.parse(localStorage.task)
            }
            if ((this.task_list.length > 0) && (this.selectedPoint.point_name == 'Main bearings')) {
              this.loadChart()
                          } else if (this.selectedPoint.point_name == 'Camshaft bearings') {
                this.loadChart()
                this.vallist = []
              } else {
                this.vallist = []
              }
          }
        }
      }

    loadChartBalance(device_id, raport_number, task_id, chart_type_id) {
        
        this.chartMainBearingsReady = false;
        this.http.getBalanceChart(device_id, raport_number, 38, "relative_constant")
        .subscribe(response => {
           

            this.resultjson = response,
              this.colorlist = response['backgroundColor'],
              this.vallist = response['data'],
              this.labels = response['pointNames'],
              this.name = response['label']
            this.barChartLabels = this.labels,
              this.barChartOptions = {
                tooltips: {},
                scaleShowVerticalLines: false,
                responsive: true,
                title: {
                  display: true,
                  text: this.name
                },
                annotation: {
                  annotations: [{
                    drawTime: "afterDatasetsDraw",
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'y-axis-0',
                    value: response.top,
                    borderColor: 'tomato',
                    borderWidth: 1
                  }, {
                    drawTime: "afterDatasetsDraw",
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'y-axis-0',
                    value: response.bot,
                    borderColor: 'tomato',
                    borderWidth: 1
                  }
                  ]
                } as ChartOptions,
    
                plugins: [ChartAnnotation],
                maintainAspectRatio: false,
                aspectRatio: 1
              };
    
            this.barChartData = [
              {
                data: this.vallist,
                backgroundColor: this.colorlist,
                label: this.selectedTask.task_name + ' [' + response['unit'] + ']'
              },
            ];
          })
      }

    

      clickElement(e) {
        if (e.active.length > 0) {
          var point_name = e.active[0]._view.label
          this.http.getPointId(
            this.selectedRaportNumber.raport_number,
            this.selectedMachinery.device_id,
            this.selectedTask.task_id,
            point_name).subscribe(response => {
              var point_id = response.point_id
              this.router.navigate(['/modules/numeric-reference/'
                + this.selectedRaportNumber.raport_number + '/'
                + this.selectedMachinery.device_id + '/'
                + 38 + '/'
                + point_id + '/'
                + 'true'
              ]);
            });
        };
      };

    loadTable() {
        this.http.getParametersTable(this.selectedMachinery.device_id, this.selectedRaportNumber.raport_number)
            .subscribe(response => {
                this.exampleTable = response,
                    this.pointsList = response.points,
                    this.parametersList = response.parameters
                    
                this.chartReady = false
                this.chartReadyy = false
            });
    }

    onChangeOwner() {
        localStorage.removeItem('owner')
        localStorage.removeItem('ship')
        localStorage.removeItem('device')
        localStorage.removeItem('report_number')
        localStorage.removeItem('task')
        localStorage.removeItem('point')
        localStorage.removeItem('ship_list')
        localStorage.removeItem('task_point_list')
        localStorage.removeItem('point_list')
        localStorage.removeItem('raport_list')
        localStorage.removeItem('machinery_list')
        localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
        this.machinery_list = []
        this.raport_list = []
        this.task_list = []
        this.ship_list = []
        this.loadAdminShipList()
    }

    onChangeShip() {
        localStorage.removeItem('ship')
        localStorage.removeItem('device')
        localStorage.removeItem('report_number')
        localStorage.removeItem('task')
        localStorage.removeItem('point')
        localStorage.removeItem('task_point_list')
        localStorage.removeItem('point_list')
        localStorage.removeItem('raport_list')
        localStorage.removeItem('machinery_list')
        localStorage.setItem('ship', JSON.stringify(this.selectedShip));
        this.machinery_list = []
        this.raport_list = []
        this.task_list = []
        this.loadMachineryList()
    }

    onChangeMachinery() {
        localStorage.removeItem('device')
        localStorage.removeItem('report_number')
        localStorage.removeItem('task')
        localStorage.removeItem('point')
        localStorage.removeItem('task_point_list')
        localStorage.removeItem('task_device_list')
        localStorage.removeItem('point_list')
        localStorage.removeItem('raport_list')
        localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
        this.raport_list = []
        this.task_list = []
        this.point_list = []
        this.loadRaportList()
    }

    onChangeRaport() {
        localStorage.removeItem('report_number')
        localStorage.removeItem('task')
        localStorage.removeItem('point')
        localStorage.removeItem('task_point_list')
        localStorage.removeItem('task_device_list')
        localStorage.removeItem('point_list')
        localStorage.setItem('report_number', JSON.stringify(this.selectedRaportNumber));
        this.task_list = []
        this.point_list = []
        this.loadPointList()
        this.loadTaskList()
    }

    onChangePoint() {
        localStorage.removeItem('point')
        localStorage.setItem('point', JSON.stringify(this.selectedPoint));
        
        if (this.selectedPoint.point_name == 'Main bearings') {
            this.loadTable()
            this.chartReady = false
           // this.loadTaskList()
            this.camshaftValList = []
            this.loadChart()



        } else if (this.selectedPoint.point_name == 'Camshaft bearings') {
            this.loadChart()
            this.vallist = [];
            this.parametersList = [];
        }
        
        else {
            this.exampleTable = undefined;
            this.pointsList = [];
            this.parametersList = [];
            this.vallist = []
            this.task_list = []
            this.loadChart()
        }

    }
    rediretToNumericReference(task_id, point_id) {
        this.router.navigate(['/modules/numeric-reference/'
            + this.selectedRaportNumber.raport_number + '/'
            + this.selectedMachinery.device_id + '/'
            + task_id + '/'
            + point_id + '/'
            + 'true'
        ]);
    }

    rediretToNumericReferenceNotPoint(task_id) {
        this.router.navigate(['/modules/numeric-reference/'
            + this.selectedRaportNumber.raport_number + '/'
            + this.selectedMachinery.device_id + '/'
            + task_id + '/'
            + 'false'
        ]);
    }

    checkAdmin() {
        this.AuthServiceApi.isAdmin().subscribe(response => {
            this.isAdmin = response.isadmin
            if (this.isAdmin) {
                this.loadOwnerList()
            } else {
                this.LoadUserShipList()
            }
        })
    }

}
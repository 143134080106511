import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../auth/auth.guard';
import { AdminGuard } from '../auth/admin.guard';

import { PagesComponent } from '../pages/pages.component';
import { CylinderBalanceComponent } from './cylinder-balance/cylinder-balance.component';
import { NumericReferenceComponent } from '../pages/numeric-reference/numeric-reference.component';
import { ChartDataComponent } from './chart-data/chart-data.component';
import { OilAnalysisComponent } from './oil-analysis/oil-analysis.component';
import { RemarksComponent } from './remarks/remarks.component';
import { ElearningComponent } from './elearning/elearning.component';

import { AdminComponent } from './admin/admin.component';
import { UserEditComponent } from './admin/user-edit/user-edit.component';
import { UserAddComponent } from './admin/user-add/user-add.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';



const routes: Routes = [
  {
    path: 'modules',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/modules/cylinder-balance', pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'cylinder-balance', component: CylinderBalanceComponent, canActivate: [AuthGuard] },
      { path: 'cylinder-balance/:raport_number/:device_id/:task_id', component: CylinderBalanceComponent, canActivate: [AuthGuard] },
      { path: 'cylinder-balance/:ship_id', component: CylinderBalanceComponent, canActivate: [AuthGuard] },
      { path: 'cylinder-balance/:ship_id/:device_id', component: CylinderBalanceComponent, canActivate: [AuthGuard] },
      { path: 'numeric-reference', component: NumericReferenceComponent, canActivate: [AuthGuard] },
      { path: 'numeric-reference/:raport_number/:device_id/:task_id/:point_id/:point_bool', component: NumericReferenceComponent, canActivate: [AuthGuard] },
      { path: 'numeric-reference/:raport_number/:device_id/:task_id/:point_bool', component: NumericReferenceComponent, canActivate: [AuthGuard] },
      { path: 'oil-analysis', component: OilAnalysisComponent, canActivate: [AuthGuard] },
      { path: 'remarks', component: RemarksComponent, canActivate: [AuthGuard] },
      { path: 'remarks/:ship_id', component: RemarksComponent, canActivate: [AuthGuard] },
      { path: 'remarks/:ship_id/:device_id', component: RemarksComponent, canActivate: [AuthGuard] },
      { path: 'elearning', component: ElearningComponent, canActivate: [AuthGuard] },
      { path: 'chart-data', component: ChartDataComponent, canActivate: [AuthGuard] },
      { path: 'maintenance', component: MaintenanceComponent, canActivate: [AuthGuard]},
      { path: 'admin', component: AdminComponent, canActivate: [AuthGuard, AdminGuard] },
      { path: 'admin/edit/:user_id', component: UserEditComponent, canActivate: [AuthGuard, AdminGuard] },
      { path: 'admin/add', component: UserAddComponent, canActivate: [AuthGuard, AdminGuard] },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] }]
  },

]


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class PagesRoutingModule {
}

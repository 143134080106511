import { Component, OnInit, EventEmitter } from "@angular/core";
import { ChartOptions } from "chart.js";
import * as ChartAnnotation from "chartjs-plugin-annotation";
import { Subscription } from "rxjs";
import { Maintenance } from "./maintenance.model";
import { MaintenanceApiService } from "./maintenance-api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { PagesService } from "../pages.service";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {
  title;
  CylinderBalanceListSubs: Subscription;
  CylinderBalanceList: Maintenance[];
  resultjson;
  barChartData;
  chartReady;
  barChartLabels;
  barChartOptions;

  public isAdmin = undefined;
  public ownersListSubs: Subscription;
  public exampleTable = undefined;
  public exampleTempTable = undefined;
  public pointsList = [];
  public pointsTempList = [];

  public parametersList = [];
  public parametersTempList = [];

  public owner_list = [];
  public selectedOwner = undefined;
  public ship_list = [];
  public selectedShip = undefined;
  public raport_list = [];
  public selectedRaportNumber = undefined;
  public machinery_list = [];
  public selectedMachinery = undefined;
  public task_list = [];
  public selectedTask = undefined;
  public chart_type_list = [
    { chart_type_id: "absolute", chart_type_name: "Absolute" },
    { chart_type_id: "relative_percent", chart_type_name: "Relative percent" },
    {
      chart_type_id: "relative_constant ",
      chart_type_name: "Relative constant",
    },
  ];
  public selectedChartType = undefined;
  public maintenance = [];
  public fire_oder = undefined;

  public measurement_date = undefined;

  onclick: EventEmitter<any> = new EventEmitter();

  constructor(
    private MaintenanceApi: MaintenanceApiService,
    private AuthServiceApi: AuthService,
    private http: PagesService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public barChartType = "bar";
  public barChartLegend = false;
  public colorlist;
  public vallist = [];
  public name;
  public labels;

  ngOnInit() {
    this.checkAdmin();
    localStorage.removeItem("task_device_list");
    localStorage.removeItem("pointCheck");
    if (localStorage.chart_type === undefined) {
      const chartTypeIndex = this.chart_type_list.findIndex(
        (task) => task.chart_type_id === "relative_percent"
      );

      if (chartTypeIndex !== -1) {
        this.selectedChartType = this.chart_type_list[chartTypeIndex];

        localStorage.setItem(
          "chart_type",
          JSON.stringify(this.selectedChartType)
        );
      } else {
        this.selectedChartType = this.chart_type_list[0];

      }
    } else {
      this.selectedChartType = JSON.parse(localStorage.chart_type);
    }
  }

  ngOnDestroy() {
    if (this.CylinderBalanceListSubs) {
      this.CylinderBalanceListSubs.unsubscribe();
    }
  }
  loadOwnerList() {
    if (localStorage.owner_list === undefined) {
      this.http.getOwnersList().subscribe((response) => {
        for (let item of response.owner_list) {
          this.owner_list.push({ owner_id: item[0], owner_name: item[1] });
        }
        localStorage.setItem("owner_list", JSON.stringify(this.owner_list));
        this.selectedOwner = this.owner_list[0];
        localStorage.setItem("owner", JSON.stringify(this.selectedOwner));
        this.loadAdminShipList();
      });
    } else {
      this.owner_list = JSON.parse(localStorage.owner_list);
      if (localStorage.owner === undefined) {
        this.selectedOwner = this.owner_list[0];
        localStorage.setItem("owner", JSON.stringify(this.selectedOwner));
      } else {
        this.selectedOwner = JSON.parse(localStorage.owner);
      }
      this.loadAdminShipList();
    }
  }

  loadAdminShipList() {
    if (this.route.snapshot.paramMap["params"]["ship_id"]) {
      this.http
        .getAdminShipsList(this.selectedOwner.owner_id)
        .subscribe((response) => {
          for (let item of response.ship_list) {
            this.ship_list.push({ ship_id: item[0], ship_name: item[1] });
          }
          localStorage.setItem("ship_list", JSON.stringify(this.ship_list));
          this.http
            .getShipName(this.route.snapshot.paramMap["params"]["ship_id"])
            .subscribe((response2) => {
              this.selectedShip = {
                ship_id: this.route.snapshot.paramMap["params"]["ship_id"],
                ship_name: response2,
              };
              localStorage.setItem("ship", JSON.stringify(this.selectedShip));
              if (this.ship_list.length > 0) {
                this.loadMachineryList();
              }
            });
        });
    } else {
      if (localStorage.ship_list === undefined) {
        this.http
          .getAdminShipsList(this.selectedOwner.owner_id)
          .subscribe((response) => {
            for (let item of response.ship_list) {
              this.ship_list.push({ ship_id: item[0], ship_name: item[1] });
            }
            localStorage.setItem("ship_list", JSON.stringify(this.ship_list));
            this.selectedShip = this.ship_list[0];
            localStorage.setItem("ship", JSON.stringify(this.selectedShip));
            if (this.ship_list.length > 0) {
              this.loadMachineryList();
            }
          });
      } else {
        this.ship_list = JSON.parse(localStorage.ship_list);
        if (localStorage.ship === undefined) {
          this.selectedShip = this.ship_list[0];
          localStorage.setItem("ship", JSON.stringify(this.selectedShip));
        } else {
          this.selectedShip = JSON.parse(localStorage.ship);
        }
        if (this.ship_list.length > 0) {
          this.loadMachineryList();
        }
      }
    }
  }

  LoadUserShipList() {
    if (this.route.snapshot.paramMap["params"]["ship_id"]) {
      this.http.getUserShipsList().subscribe((response) => {
        for (let item of response.ship_list) {
          this.ship_list.push({ ship_id: item[0], ship_name: item[1] });
        }
        localStorage.setItem("ship_list", JSON.stringify(this.ship_list));
        this.http
          .getShipName(this.route.snapshot.paramMap["params"]["ship_id"])
          .subscribe((response2) => {
            this.selectedShip = {
              ship_id: this.route.snapshot.paramMap["params"]["ship_id"],
              ship_name: response2,
            };
            localStorage.setItem("ship", JSON.stringify(this.selectedShip));
            if (this.ship_list.length > 0) {
              this.loadMachineryList();
            }
          });
      });
    } else {
      if (localStorage.ship_list === undefined) {
        this.http.getUserShipsList().subscribe((response) => {
          for (let item of response.ship_list) {
            this.ship_list.push({ ship_id: item[0], ship_name: item[1] });
          }
          localStorage.setItem("ship_list", JSON.stringify(this.ship_list));
          this.selectedShip = this.ship_list[0];
          localStorage.setItem("ship", JSON.stringify(this.selectedShip));
          if (this.ship_list.length > 0) {
            this.loadMachineryList();
          }
        });
      } else {
        this.ship_list = JSON.parse(localStorage.ship_list);
        if (localStorage.ship === undefined) {
          this.selectedShip = this.ship_list[0];
          localStorage.setItem("ship", JSON.stringify(this.selectedShip));
        } else {
          this.selectedShip = JSON.parse(localStorage.ship);
        }
        if (this.ship_list.length > 0) {
          this.loadMachineryList();
        }
      }
    }
  }

  loadMachineryList() {

    if (this.route.snapshot.paramMap["params"]["device_id"]) {
      this.http
        .getMachineryList(this.selectedShip.ship_id)
        .subscribe((response) => {

          for (let item of response.device_list) {
            this.machinery_list.push({
              device_id: item[0],
              device_name: item[1],
            });
          }
          localStorage.setItem(
            "machinery_list",
            JSON.stringify(this.machinery_list)
          );
          this.http
            .getDeviceName(this.route.snapshot.paramMap["params"]["device_id"])
            .subscribe((response) => {

              this.selectedMachinery = {
                device_id: this.route.snapshot.paramMap["params"]["device_id"],
                device_name: response,
              };
              localStorage.setItem(
                "device",
                JSON.stringify(this.selectedMachinery)
              );
              if (this.machinery_list.length > 0) {
                this.loadRaportList();
              }
            });
        });
    } else {
      if (localStorage.machinery_list === undefined) {
        this.http
          .getMachineryList(this.selectedShip.ship_id)
          .subscribe((response) => {

            for (let item of response.device_list) {
              this.machinery_list.push({
                device_id: item[0],
                device_name: item[1],
              });
            }
            localStorage.setItem(
              "machinery_list",
              JSON.stringify(this.machinery_list)
            );
            this.selectedMachinery = this.machinery_list[0];
            localStorage.setItem(
              "device",
              JSON.stringify(this.selectedMachinery)
            );
            if (this.machinery_list.length > 0) {
              this.loadRaportList();
            }
          });
      } else {
        this.machinery_list = JSON.parse(localStorage.machinery_list);
        if (localStorage.device === undefined) {
          this.selectedMachinery = this.machinery_list[0];
          localStorage.setItem(
            "device",
            JSON.stringify(this.selectedMachinery)
          );
        } else {
          this.selectedMachinery = JSON.parse(localStorage.device);
        }
        this.loadRaportList();
      }
    }
  }

  loadRaportList() {
    if (localStorage.raport_list === undefined) {
      this.http
        .getRaportList(this.selectedMachinery.device_id)
        .subscribe((response) => {
          for (let item of response.raport_list) {
            this.raport_list.push({ raport_number: item[0] });
          }
          localStorage.setItem("raport_list", JSON.stringify(this.raport_list));
          this.selectedRaportNumber = this.raport_list[0];
          localStorage.setItem(
            "report_number",
            JSON.stringify(this.selectedRaportNumber)
          );
      
          this.loadPerformanceReports(
            this.selectedMachinery.device_id,
            this.selectedRaportNumber.raport_number
          );
        });
    } else {
      this.raport_list = JSON.parse(localStorage.raport_list);
      if (localStorage.report_number === undefined) {
        this.selectedRaportNumber = this.raport_list[0];
        localStorage.setItem(
          "report_number",
          JSON.stringify(this.selectedRaportNumber)
        );
      } else {
        this.selectedRaportNumber = JSON.parse(localStorage.report_number);
      }
  
      this.loadPerformanceReports(
        this.selectedMachinery.device_id,
        this.selectedRaportNumber.raport_number
      );
    }
  }

  onChangeOwner() {
    localStorage.removeItem("owner");
    localStorage.removeItem("ship");
    localStorage.removeItem("device");
    localStorage.removeItem("report_number");
    localStorage.removeItem("task");
    localStorage.removeItem("point");
    localStorage.removeItem("ship_list");
    localStorage.removeItem("task_point_list");
    localStorage.removeItem("point_list");
    localStorage.removeItem("raport_list");
    localStorage.removeItem("machinery_list");
    localStorage.removeItem("oil_raport_list");
    localStorage.removeItem("oil_report_number");
    localStorage.setItem("owner", JSON.stringify(this.selectedOwner));
    this.machinery_list = [];
    this.raport_list = [];
    this.task_list = [];
    this.ship_list = [];
    this.loadAdminShipList();
  }

  onChangeShip() {
    localStorage.removeItem("ship");
    localStorage.removeItem("device");
    localStorage.removeItem("report_number");
    localStorage.removeItem("task");
    localStorage.removeItem("point");
    localStorage.removeItem("task_point_list");
    localStorage.removeItem("point_list");
    localStorage.removeItem("raport_list");
    localStorage.removeItem("machinery_list");
    localStorage.removeItem("oil_raport_list");
    localStorage.removeItem("oil_report_number");
    localStorage.setItem("ship", JSON.stringify(this.selectedShip));
    this.machinery_list = [];
    this.raport_list = [];
    this.task_list = [];
    this.loadMachineryList();

  }

  onChangeMachinery() {
    localStorage.removeItem("device");
    localStorage.removeItem("report_number");
    localStorage.removeItem("task");
    localStorage.removeItem("point");
    localStorage.removeItem("task_point_list");
    localStorage.removeItem("point_list");
    localStorage.removeItem("raport_list");
    localStorage.removeItem("oil_raport_list");
    localStorage.removeItem("oil_report_number");
    localStorage.setItem("device", JSON.stringify(this.selectedMachinery));
    this.raport_list = [];
    this.task_list = [];
    this.loadRaportList();
  }

  onChangeRaport() {
    localStorage.removeItem("report_number");
    localStorage.removeItem("task");
    localStorage.removeItem("point");
    localStorage.removeItem("task_point_list");
    localStorage.removeItem("point_list");
    localStorage.setItem(
      "report_number",
      JSON.stringify(this.selectedRaportNumber)
    );
    this.task_list = [];

    this.loadPerformanceReports(
      this.selectedMachinery.device_id,
      this.selectedRaportNumber.raport_number
    );
  }
  


  loadPerformanceReports(device_id: string, raport_number: number) {
    this.MaintenanceApi.getPerformanceReports(device_id, raport_number).subscribe((response) => {
  
      this.exampleTable = response.tasks[0];
      this.maintenance = response.device;
      
      this.parametersList = Object.keys(this.exampleTable).map(taskName => ({
        task_name: taskName,
        data: this.exampleTable[taskName]
      }));
      
    });
  }
  
  




  checkAdmin() {
    this.AuthServiceApi.isAdmin().subscribe((response) => {
      this.isAdmin = response.isadmin;
      if (this.isAdmin) {
        this.loadOwnerList();
      } else {
        this.LoadUserShipList();
      }
    });
  }
}

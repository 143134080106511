import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { PagesService } from '../pages.service';
import { ToasterService } from 'angular2-toaster';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-remarks',
  templateUrl: './remarks.component.html',
  styleUrls: ['./remarks.component.css']
})
export class RemarksComponent implements OnInit {

  public isAdmin = undefined
  public owner_list = [];
  public selectedOwner = undefined;
  public ship_list = []
  public selectedShip = undefined;
  public raport_list = [];
  public selectedRaportNumber = undefined;
  public machinery_list = [];
  public selectedMachinery = undefined;

  public point_list = [];
  public device = undefined;

  constructor(
    private AuthServiceApi: AuthService,
    private http: PagesService,
    private toastr: ToasterService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.checkAdmin()
  }

  checkAdmin() {
    this.AuthServiceApi.isAdmin().subscribe(response => {
      this.isAdmin = response.isadmin
      if (this.isAdmin) {
        this.loadOwnerList()
      } else {
        this.LoadUserShipList()
      }
    })
  }

  loadOwnerList() {
    if (localStorage.owner_list === undefined) {
      this.http.getOwnersList().subscribe(response => {
        for (let item of response.owner_list) {
          this.owner_list.push({ 'owner_id': item[0], 'owner_name': item[1] })
        }
        localStorage.setItem('owner_list', JSON.stringify(this.owner_list));
        this.selectedOwner = this.owner_list[0]
        localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
        this.loadAdminShipList()
      })
    } else {
      this.owner_list = JSON.parse(localStorage.owner_list)
      if (localStorage.owner === undefined) {
        this.selectedOwner = this.owner_list[0];
        localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
      } else {
        this.selectedOwner = JSON.parse(localStorage.owner)
      }
      this.loadAdminShipList()
    }
  }

  loadAdminShipList() {
    if (this.route.snapshot.paramMap['params']['ship_id']){
      this.http.getAdminShipsList(this.selectedOwner.owner_id).subscribe(response => {
        for (let item of response.ship_list) {
          this.ship_list.push({ 'ship_id': item[0], 'ship_name': item[1] })
        }
        localStorage.setItem('ship_list', JSON.stringify(this.ship_list));
        this.http.getShipName(this.route.snapshot.paramMap['params']['ship_id']).subscribe(response2 => {
          this.selectedShip = { 'ship_id': this.route.snapshot.paramMap['params']['ship_id'], 'ship_name': response2 }
          localStorage.setItem('ship', JSON.stringify(this.selectedShip));
          if (this.ship_list.length > 0) {
            this.loadMachineryList()
          }
        })
      })
    } else {
      if (localStorage.ship_list === undefined) {
        this.http.getAdminShipsList(this.selectedOwner.owner_id).subscribe(response => {
          for (let item of response.ship_list) {
            this.ship_list.push({ 'ship_id': item[0], 'ship_name': item[1] })
          }
          localStorage.setItem('ship_list', JSON.stringify(this.ship_list));
          this.selectedShip = this.ship_list[0]
          localStorage.setItem('ship', JSON.stringify(this.selectedShip));
          if (this.ship_list.length > 0) {
            this.loadMachineryList()
          }
        })
      } else {
        this.ship_list = JSON.parse(localStorage.ship_list)
        if (localStorage.ship === undefined) {
          this.selectedShip = this.ship_list[0];
          localStorage.setItem('ship', JSON.stringify(this.selectedShip));
        } else {
          this.selectedShip = JSON.parse(localStorage.ship)
        }
        if (this.ship_list.length > 0) {
          this.loadMachineryList()
        }
      }
    }
  }

  LoadUserShipList() {
    if (this.route.snapshot.paramMap['params']['ship_id']){
      this.http.getUserShipsList().subscribe(response => {
        for (let item of response.ship_list) {
          this.ship_list.push({ 'ship_id': item[0], 'ship_name': item[1] })
        }
        localStorage.setItem('ship_list', JSON.stringify(this.ship_list));
        this.http.getShipName(this.route.snapshot.paramMap['params']['ship_id']).subscribe(response2 => {
          this.selectedShip = { 'ship_id': this.route.snapshot.paramMap['params']['ship_id'], 'ship_name': response2 }
          localStorage.setItem('ship', JSON.stringify(this.selectedShip));
          if (this.ship_list.length > 0) {
            this.loadMachineryList()
          }
        })
      })
    } else {
      if (localStorage.ship_list === undefined) {
        this.http.getUserShipsList().subscribe(response => {
          for (let item of response.ship_list) {
            this.ship_list.push({ 'ship_id': item[0], 'ship_name': item[1] })
          }
          localStorage.setItem('ship_list', JSON.stringify(this.ship_list));
          this.http.getShipName(this.route.snapshot.paramMap['params']['ship_id']).subscribe(response2 => {
            this.selectedShip = { 'ship_id': this.route.snapshot.paramMap['params']['ship_id'], 'ship_name': response2 }
            localStorage.setItem('ship', JSON.stringify(this.selectedShip));
            if (this.ship_list.length > 0) {
              this.loadMachineryList()
            }
          })
        })
      } else {
        this.ship_list = JSON.parse(localStorage.ship_list)
        if (localStorage.ship === undefined) {
          this.selectedShip = this.ship_list[0];
          localStorage.setItem('ship', JSON.stringify(this.selectedShip));
        } else {
          this.selectedShip = JSON.parse(localStorage.ship)
        }
        if (this.ship_list.length > 0) {
          this.loadMachineryList()
        }
      }
    }
  }

  loadMachineryList() {
    if (this.route.snapshot.paramMap['params']['device_id']){
      this.http.getMachineryList(this.selectedShip.ship_id).subscribe(response => {
        for (let item of response.device_list) {
          this.machinery_list.push({ 'device_id': item[0], 'device_name': item[1] })
        }
        localStorage.setItem('machinery_list', JSON.stringify(this.machinery_list));
        this.http.getDeviceName(this.route.snapshot.paramMap['params']['device_id']).subscribe(response =>{
          this.selectedMachinery = { 'device_id': this.route.snapshot.paramMap['params']['device_id'], 'device_name': response }
          localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
          if (this.machinery_list.length > 0) {
            this.loadRaportList();
          }
        })
      })
    } else {
      if (localStorage.machinery_list === undefined) {
        this.http.getMachineryList(this.selectedShip.ship_id).subscribe(response => {
          for (let item of response.device_list) {
            this.machinery_list.push({ 'device_id': item[0], 'device_name': item[1] })
          }
          localStorage.setItem('machinery_list', JSON.stringify(this.machinery_list));
          this.selectedMachinery = this.machinery_list[0];
          localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
          if (this.machinery_list.length > 0) {
            this.loadRaportList();
          }
        })
      } else {
        this.machinery_list = JSON.parse(localStorage.machinery_list)
        if (localStorage.device === undefined) {
          this.selectedMachinery = this.machinery_list[0];
          localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
        } else {
          this.selectedMachinery = JSON.parse(localStorage.device)
        }
        this.loadRaportList();
      }
    }

  }

  loadRaportList() {
    if (localStorage.raport_list === undefined) {
      this.http.getRaportList(this.selectedMachinery.device_id).subscribe(response => {
        for (let item of response.raport_list) {
          this.raport_list.push({ 'raport_number': item[0] })
        }
        localStorage.setItem('raport_list', JSON.stringify(this.raport_list));
        this.selectedRaportNumber = this.raport_list[0];
        localStorage.setItem('report_number', JSON.stringify(this.selectedRaportNumber));
        this.loadRemarksData()
      })
    } else {
      this.raport_list = JSON.parse(localStorage.raport_list)
      if (localStorage.report_number === undefined) {
        this.selectedRaportNumber = this.raport_list[0];
        localStorage.setItem('report_number', JSON.stringify(this.selectedRaportNumber));
      } else {
        this.selectedRaportNumber = JSON.parse(localStorage.report_number)
      }
      this.loadRemarksData()
    }
  }

  loadRemarksData() {
    this.http.getRemarks(this.selectedMachinery.device_id, this.selectedRaportNumber.raport_number, this.isAdmin).subscribe(response => {
      this.point_list = response.points_list
      this.device = response.device
    })
  }


  onChangeOwner() {
    localStorage.removeItem('owner')
    localStorage.removeItem('ship')
    localStorage.removeItem('device')
    localStorage.removeItem('report_number')
    localStorage.removeItem('task')
    localStorage.removeItem('point')
    localStorage.removeItem('ship_list')
    localStorage.removeItem('task_point_list')
    localStorage.removeItem('point_list')
    localStorage.removeItem('raport_list')
    localStorage.removeItem('machinery_list')
    localStorage.removeItem('oil_raport_list');
    localStorage.removeItem('oil_report_number');
    localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
    this.machinery_list = []
    this.raport_list = []
    this.ship_list = []
    this.loadAdminShipList()
  }

  onChangeShip() {
    localStorage.removeItem('ship')
    localStorage.removeItem('device')
    localStorage.removeItem('report_number')
    localStorage.removeItem('task')
    localStorage.removeItem('point')
    localStorage.removeItem('task_point_list')
    localStorage.removeItem('point_list')
    localStorage.removeItem('raport_list')
    localStorage.removeItem('machinery_list')
    localStorage.removeItem('oil_raport_list');
    localStorage.removeItem('oil_report_number');
    localStorage.setItem('ship', JSON.stringify(this.selectedShip));
    this.machinery_list = []
    this.raport_list = []
    this.loadMachineryList()
  }

  onChangeMachinery() {
    localStorage.removeItem('device')
    localStorage.removeItem('report_number')
    localStorage.removeItem('task')
    localStorage.removeItem('point')
    localStorage.removeItem('task_point_list')
    localStorage.removeItem('point_list')
    localStorage.removeItem('raport_list')
    localStorage.removeItem('oil_raport_list');
    localStorage.removeItem('oil_report_number');
    localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
    this.raport_list = []
    this.loadRaportList()
  }

  onChangeRaport() {
    localStorage.removeItem('report_number')
    localStorage.removeItem('task')
    localStorage.removeItem('point')
    localStorage.removeItem('task_point_list')
    localStorage.removeItem('point_list')
    localStorage.setItem('report_number', JSON.stringify(this.selectedRaportNumber));
    this.loadRemarksData()
  }

  changePointVisible(point_id) {
    let checbox_status = (<HTMLInputElement>document.getElementById('checbox_visible_' + String(point_id))).checked
    const json_data = {
      point_id: point_id,
      checbox_status: checbox_status
    }

    this.http.changeRemarkPointVisible(json_data).subscribe(response => {
      if(response.status == "success"){
        this.toastr.pop('success', 'Success', 'The view for the user has been changed');
      } else {
        this.toastr.pop('error', 'Error', 'The view for the user has not been changed');
      }
    });
  }

  changeDeviceVisible(device_id) {
    let checbox_status = (<HTMLInputElement>document.getElementById('checbox_visible_device_' + String(device_id))).checked
    const json_data = {
      device_id: device_id,
      checbox_status: checbox_status
    }
    this.http.changeRemarkDeviceVisible(json_data).subscribe(response => {
      if(response.status == "success"){
        this.toastr.pop('success', 'Success', 'The view for the user has been changed');
      } else {
        this.toastr.pop('error', 'Error', 'The view for the user has not been changed');
      }
    });
  };

  changeAllPointsAndDeviceVisible(){
    let checbox_status = (<HTMLInputElement>document.getElementById('check_all')).checked
    const json_data = {
      raport_number: this.selectedRaportNumber.raport_number,
      device_id: this.selectedMachinery.device_id,
      checbox_status: checbox_status
    }
    this.http.changeAllRemarkVisible(json_data).subscribe(response => {
      if(response.status == "success"){
        this.toastr.pop('success', 'Success', 'The view for the user has been changed');
        this.loadRemarksData()
      } else {
        this.toastr.pop('error', 'Error', 'The view for the user has not been changed');
      }
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { AdminApiService } from './admin-api.service'
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ChangeDeviceTaskComponent } from './change-device-task/change-device-task.component';
import { ChangePointTaskComponent } from './change-point-task/change-point-task.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  modalRef: BsModalRef;
 // ALL OWNER LIST
  public im_owner_list = [];
  public owner_list = [];
  // ALL SELECTED OWNER
  public selectedIMOwner = undefined;
  public selectedUpdateIMOwner = undefined;
  public selectedOwner = undefined;
  public selectedReportOwner = undefined;

  // ALL SHIP LIST
  public ship_list = []
  public im_ship_list = [];
  public im_update_ship_list = [];
  public report_ship_list = [];
  // ALL SELECTED SHIP 
  public selectedIMShip = undefined;
  public selectedUpdateIMShip = undefined;
  public selectedShip = undefined;
  public selectedReportShip = undefined;

  public users = []
  public report_list = [];
  public selectedRaportNumber = undefined;
  

  public device_blacktable = []
  public point_blacktable = []

  public isLoad = false;
  public first_load = true;


  constructor(
    private AdminApi: AdminApiService,
    private router: Router,
    private toastr: ToasterService,
    private route: ActivatedRoute,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.AdminApi.getUsers().subscribe(response => {
      for (let user of response.users_list) {
        this.users.push(
          {
            'user_pkey': user[0],
            'full_name': user[1],
            'login': user[2],
            'is_active': user[3]
          })
      }
      this.loadDeviceBlackTable()
      this.loadPointBlackTable()
      this.loadIMOwnersList()
    })
  }

  editUser(user_id) {
    this.router.navigate(['/modules/admin/edit/' + user_id]);
  }

  addUser() {
    this.router.navigate(['/modules/admin/add']);
  }

  loadIMOwnersList() {
    this.AdminApi.loadOwnersFromImDB().subscribe(response => {
      for (let res of response) {
        this.im_owner_list.push({ 'owner_id': res['id'], 'owner_name': res['name'] })
      }
      this.selectedIMOwner = this.im_owner_list[0]
      this.selectedUpdateIMOwner = this.im_owner_list[0]
      this.loadIMShipsList()
    })
  }

  loadIMShipsList() {
    this.im_ship_list = []
    this.AdminApi.loadShipsFromImDB(this.selectedIMOwner['owner_id']).subscribe(response => {
      for (let res of response) {
        this.im_ship_list.push({ 'ship_id': res['id'], 'ship_name': res['name'] })
      }
      this.selectedIMShip = this.im_ship_list[0]
      this.loadUpdateIMShipsList()
    })
  }

  loadUpdateIMShipsList() {
    this.im_update_ship_list = []
    this.AdminApi.loadShipsFromImDB(this.selectedUpdateIMOwner['owner_id']).subscribe(response => {
      for (let res of response) {
        this.im_update_ship_list.push({ 'ship_id': res['id'], 'ship_name': res['name'] })
      }
      this.selectedUpdateIMShip = this.im_update_ship_list[0]
      if (this.first_load) {
        this.loadOwnerList()
      }

    })
  }

  loadReportShipsList(){
    this.report_ship_list = []
    this.AdminApi.getShipsList(this.selectedReportOwner.owner_id).subscribe(response => {
      for (let item of response.ship_list) {
        this.report_ship_list.push({ 'ship_id': item[0], 'ship_name': item[1] })
      }
      if (this.report_ship_list.length > 0){
        this.selectedReportShip = this.report_ship_list[0]
        this.loadReportList()
      }
    })
  }

  loadReportList(){
    this.report_list = []
    this.AdminApi.getReportListByShip(this.selectedReportShip.ship_id).subscribe(response => {
      for (let item of response.raport_list) {
        this.report_list.push({'report_id' : item[0], 'raport_number': item[1] })
      }
      this.selectedRaportNumber = this.report_list[0]
      this.first_load = false;
    })
  }


  loadOwnerList() {
    this.AdminApi.getOwnersList().subscribe(response => {
      for (let item of response.owner_list) {
        this.owner_list.push({ 'owner_id': item[0], 'owner_name': item[1] })
      }
      this.selectedOwner = this.owner_list[0]
      this.selectedReportOwner = this.owner_list[0]
      this.loadShipList()
    })
  }

  loadShipList() {
    this.ship_list = []
    this.AdminApi.getShipsList(this.selectedOwner.owner_id).subscribe(response => {
      for (let item of response.ship_list) {
        this.ship_list.push({ 'ship_id': item[0], 'ship_name': item[1] })
      }
      this.selectedShip = this.ship_list[0]
      if (this.first_load) {
        this.loadReportShipsList()
      }
    })
  }

  loadFromImDb() {
    this.isLoad = true
    this.AdminApi.loadFromImDatabase(this.selectedIMShip['ship_id']).subscribe(response => {
      if (response.status == 'success') {
        this.toastr.pop('success', 'Success', 'The ship has been added successfully');
        this.loadShipList()
      } else {
        this.toastr.pop('error', 'Error', 'The ship has not been added');
      }
      this.isLoad = false
    })
  }

  updateFromImDb() {
    this.isLoad = true
    this.AdminApi.updateFromImDatabase(this.selectedUpdateIMShip['ship_id']).subscribe(response => {
      if (response.status == 'success') {
        this.toastr.pop('success', 'Success', 'The ship has been added successfully');
        this.loadShipList()
      } else {
        this.toastr.pop('error', 'Error', 'The ship has not been added');
      }
      this.isLoad = false
    })
  }

  deleteFromNaviDb() {
    this.isLoad = true
    this.AdminApi.deleteFromNaviDatabase(this.selectedShip['ship_id']).subscribe(response => {
      if (response.status == 'success') {
        this.toastr.pop('success', 'Success', 'The ship was successfully removed');
        this.loadShipList()
      } else {
        this.toastr.pop('error', 'Error', 'The ship has not been removed');
      }
      this.isLoad = false
    }
    )
  }

  deleteRaportFromNaviDb(){
    this.isLoad = true
    this.AdminApi.deleteReportFromNaviDatabase(this.selectedRaportNumber.report_id).subscribe(response => {
      if (response.status == 'success') {
        this.toastr.pop('success', 'Success', 'The report was successfully removed');
        this.loadReportList()
      } else {
        this.toastr.pop('error', 'Error', 'The report has not been removed');
      }
      this.isLoad = false
    }
    )
  }

  loadDeviceBlackTable() {
    this.AdminApi.getDeviceBlackTable().subscribe(response => {
      this.device_blacktable = response.blacktable
    })
  }

  loadPointBlackTable() {
    this.AdminApi.getPointBlackTable().subscribe(response => {
      this.point_blacktable = response.blacktable
    })
  }

  changeDeviceTask() {
    this.modalRef = this.modalService.show(ChangeDeviceTaskComponent, { class: 'modal-lg' });
    this.modalRef.content.onChange.subscribe(response => {
      if (response) {
        this.loadDeviceBlackTable()
      }
    })
  }

  changePointTask() {
    this.modalRef = this.modalService.show(ChangePointTaskComponent, { class: 'modal-lg' })
    this.modalRef.content.onChange.subscribe(response => {
      if (response) {
        this.loadPointBlackTable()
      }
    })
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { PagesComponent } from './pages.component'
import { PagesService } from './pages.service'
import { CylinderBalanceApiService } from './cylinder-balance/cylinder-balance-api.service';
import { NumericReferenceApiService } from './numeric-reference/numeric-reference-api.service';
import { ChartDataApiService } from './chart-data/chart-data-api.service';
import { PagesRoutingModule } from './pages-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartModule } from 'angular2-chartjs';



import { ChartsModule, ThemeService } from 'ng2-charts';
import { CylinderBalanceComponent } from './cylinder-balance/cylinder-balance.component';
import { NumericReferenceComponent } from './numeric-reference/numeric-reference.component';

import { ChartDataComponent } from './chart-data/chart-data.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin/admin.component';
import { AdminApiService } from './admin/admin-api.service';
import { UserEditComponent } from './admin/user-edit/user-edit.component';
import { UserAddComponent } from './admin/user-add/user-add.component';
import { ChangeShipComponent } from './admin/change-ship/change-ship.component';
import { ChangeDeviceTaskComponent } from './admin/change-device-task/change-device-task.component';
import { ChangePointTaskComponent } from './admin/change-point-task/change-point-task.component'
import { BsModalService, BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { OilAnalysisComponent } from './oil-analysis/oil-analysis.component';
import { RemarksComponent } from './remarks/remarks.component';
import { ElearningComponent } from './elearning/elearning.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardDetailsComponent } from './dashboard/dashboard-details/dashboard-details.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MaintenanceApiService } from './maintenance/maintenance-api.service';


@NgModule({
  declarations: [
    PagesComponent,
    CylinderBalanceComponent,
    NumericReferenceComponent,
    ChartDataComponent,
    AdminComponent,
    UserEditComponent,
    UserAddComponent,
    ChangeShipComponent,
    OilAnalysisComponent,
    ChangeDeviceTaskComponent,
    ChangePointTaskComponent,
    RemarksComponent,
    ElearningComponent,
    DashboardComponent,
    DashboardDetailsComponent,
    MaintenanceComponent
  ],
  imports: [
    ModalModule.forRoot(),
    PagesRoutingModule,
    BrowserModule,
    HttpClientModule,
    ChartsModule,
    ReactiveFormsModule,
    CommonModule,
    NgSelectModule,
    FormsModule,
    ChartModule
    
  ],
  exports: [ 
    ChartsModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule],
  providers: [
    PagesService,
    CylinderBalanceApiService,
    MaintenanceApiService,
    NumericReferenceApiService,
    ChartDataApiService,
    ThemeService,
    AdminApiService,
    BsModalService,
    BsModalRef],
  bootstrap: [PagesComponent],
  entryComponents: [
    ChangeShipComponent,
    ChangeDeviceTaskComponent,
    ChangePointTaskComponent,
    DashboardDetailsComponent]
})
export class PagesModule {
}
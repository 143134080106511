import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Observable, EMPTY, throwError, } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {CylinderBalance} from './cylinder-balance.model';

@Injectable()

export class CylinderBalanceApiService {

  constructor(private http: HttpClient) {
    
  }  

  getCylinderBalance(device_id, raport_number, task_id, chart_type_id): Observable<any> {
    return this.http.get<any>(`cylinder-balance/` + device_id + '/' + raport_number + '/' + task_id + '/' + chart_type_id).pipe(
      map(response => {
        return response;})
      )
  };

  getAllName(device_id, task_id): Observable<any> {
    return this.http.get<any>(`get_name_cylinder_balance/` + device_id + '/' + task_id).pipe(
      map(response => {return response;})
      )
  };
  getOwners(device_id, raport_number): Observable<any> {
    return this.http.get<any>('parameters-table/' + device_id + '/' + raport_number).pipe(
      map(response => {return response;})
      )
  
  };

  getPerformanceReports(device_id, raport_number): Observable<any> {
    return this.http.get<any>('parameters-table/performance-reports/' + device_id + '/' + raport_number).pipe(
      map(response => {return response;})
      )
  };

  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }
}

import { Component, OnInit, TemplateRef } from '@angular/core';
import { PagesService } from '../pages.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DashboardDetailsComponent } from './dashboard-details/dashboard-details.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  modalRef: BsModalRef;

  public isAdmin = undefined;

  public owner_list = [];

  public selectedOwner = undefined;

  readonly CHART_TYPE = 'doughnut';

  public ship_dashboard_list = []

  imgSrc: string = 'assets/img/dash_legend.png'; //


  constructor(
    private http: PagesService,
    private AuthServiceApi: AuthService,
    private router: Router,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.checkAdmin()
  };

  checkAdmin() {
    this.AuthServiceApi.isAdmin().subscribe(response => {
      this.isAdmin = response.isadmin
      if (this.isAdmin) {
        this.loadOwnerList()
      } else {
        this.loadDashboard()
      }
    })
  }


  loadOwnerList() {
    if (localStorage.owner_list === undefined) {
      this.http.getOwnersList().subscribe(response => {
        for (let item of response.owner_list) {
          this.owner_list.push({ 'owner_id': item[0], 'owner_name': item[1] })
        }
        localStorage.setItem('owner_list', JSON.stringify(this.owner_list));
        this.selectedOwner = this.owner_list[0]
        localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
        this.loadAdminDashboard()
      })
    } else {
      this.owner_list = JSON.parse(localStorage.owner_list)
      if (localStorage.owner === undefined) {
        this.selectedOwner = this.owner_list[0];
        localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
      } else {
        this.selectedOwner = JSON.parse(localStorage.owner)
      }
      this.loadAdminDashboard()
    }
  };

  loadAdminDashboard() {
    this.ship_dashboard_list = []
    this.http.getAdminDashboard(this.selectedOwner.owner_id).subscribe(response => {
      for (let ship_data of response) {
        let sets = [{
          data: [ship_data.donut1['A'], ship_data.donut1['B'], ship_data.donut1['C']],
          backgroundColor: ['#008000a1', '#ffff008f', '#ff0000ad'],
        },
        {
          data: [ship_data.donut2['green'], ship_data.donut2['yellow'], ship_data.donut2['red']],
          backgroundColor: ['#008000a1', '#ffff008f', '#ff0000ad'],
        }];

        
        if (ship_data.donut3['feedback'] === 0 && ship_data.donut3['delivered'] === 0 && ship_data.donut3['no-feedback'] === 0) {
          sets.push({
            data: [1], 
            backgroundColor: ['#008000a1'], 
          });
        } else {
          sets.push({
            data: [ship_data.donut3['feedback'], ship_data.donut3['delivered'], ship_data.donut3['no-feedback']],
            backgroundColor: ['#008000a1', '#ffff008f', '#ff0000ad'],
          });
        }
        
        let remarkLabel = 'Feedback';
        if (ship_data.donut3['feedback'] === 0 && ship_data.donut3['delivered'] === 0 && ship_data.donut3['no-feedback'] === 0) {
          remarkLabel = 'No remark send';
        }

        let data = {
          labels: [['Remarks Type A', 'Remarks Type B', 'Remarks Type C'], ['Days since the last measurement', 'Days since the last measurement', 'Days since the last measurement'], [remarkLabel, 'Delivered', 'No Feedback']],
          datasets: sets
        };
        let options = {
          plugins: {
            labels: [
              {
                render: 'value',
                fontColor: '#000',
                fontSize: 14,
                
                arc: false,
              }
            ],
          },
          percentageInnerCutout: 10,
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
                label: function (item, data) {
                   
                    if (data.datasets[item.datasetIndex].data.toString() === '1') {
                        return 'No remark send';
                    } else {
                        return data.labels[item.datasetIndex][item.index] + ': ' + data.datasets[item.datasetIndex].data[item.index];
                    }
                },
                beforeBody: function (item, data) {
                    return data.datasets[item[0].datasetIndex].label;
                }
            }
        }
        ,
          title: {
            display: true,
            text: ship_data.ship_name,
            fontSize: 24
          }
        };
        this.ship_dashboard_list.push({ 'data': data, 'options': options, 'ship_id': ship_data.ship_id, 'ship_name' : ship_data.ship_name })
      }
    })
  }

  loadDashboard() {
    this.http.getDashboard().subscribe(response => {
      for (let ship_data of response) {
        let sets = [{
          data: [ship_data.donut1['A'], ship_data.donut1['B'], ship_data.donut1['C']],
          backgroundColor: ['#008000a1', '#ffff008f', '#ff0000ad'],
        },
        {
          data: [ship_data.donut2['green'], ship_data.donut2['yellow'], ship_data.donut2['red']],
          backgroundColor: ['#008000a1', '#ffff008f', '#ff0000ad'],
        }];

        
        if (ship_data.donut3['feedback'] === 0 && ship_data.donut3['delivered'] === 0 && ship_data.donut3['no-feedback'] === 0) {
          sets.push({
            data: [1], 
            backgroundColor: ['#008000a1'], 
          });
        } else {
          sets.push({
            data: [ship_data.donut3['feedback'], ship_data.donut3['delivered'], ship_data.donut3['no-feedback']],
            backgroundColor: ['#008000a1', '#ffff008f', '#ff0000ad'],
          });
        }
        let remarkLabel = 'Feedback';
        if (ship_data.donut3['feedback'] === 0 && ship_data.donut3['delivered'] === 0 && ship_data.donut3['no-feedback'] === 0) {
          remarkLabel = 'No remark send';
        }
        let data = {
          labels: [['Remarks Type A', 'Remarks Type B', 'Remarks Type C'], ['Days since the last measurement', 'Days since the last measurement', 'Days since the last measurement'], [remarkLabel, 'Delivered', 'No Feedback']],
          datasets: sets
        };
        let options = {
          plugins: {
            labels: [
              {
                render: 'value',
                fontColor: '#000',
                fontSize: 14,
                arc: false,
              }
            ],
          },
          percentageInnerCutout: 10,
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
                label: function (item, data) {
                   
                    if (data.datasets[item.datasetIndex].data.toString() === '1') {
                        return 'No remark send';
                    } else {
                        return data.labels[item.datasetIndex][item.index] + ': ' + data.datasets[item.datasetIndex].data[item.index];
                    }
                },
                beforeBody: function (item, data) {
                    return data.datasets[item[0].datasetIndex].label;
                }
            }
        },
          title: {
            display: true,
            text: ship_data.ship_name,
            fontSize: 24
          }
        };
        this.ship_dashboard_list.push({ 'data': data, 'options': options, 'ship_id': ship_data.ship_id, 'ship_name' : ship_data.ship_name})
      }
    })
  }


  openImageModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-xl' });
  }

  clickElement(event, ship_id, ship_name) {
 //   if (event.length > 0) {
   //   if (event[0]._datasetIndex == 0 || event[0]._datasetIndex == 2) {
   //     this.router.navigate(['/modules/remarks/' + String(ship_id)]);
   //   } else {
     //   this.router.navigate(['/modules/cylinder-balance/' + String(ship_id)]);
   //   }
   // } else {
      const initialState = {
        ship_id: ship_id,
        ship_name: ship_name
      };
      this.modalRef = this.modalService.show( DashboardDetailsComponent, {initialState, class: 'modal-lg' });
  //  }
  };


  onChangeOwner() {
    localStorage.removeItem('owner')
    localStorage.removeItem('ship')
    localStorage.removeItem('device')
    localStorage.removeItem('report_number')
    localStorage.removeItem('task')
    localStorage.removeItem('point')
    localStorage.removeItem('ship_list')
    localStorage.removeItem('task_point_list')
    localStorage.removeItem('point_list')
    localStorage.removeItem('raport_list')
    localStorage.removeItem('machinery_list')
    localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
    this.loadAdminDashboard()
  }



}

import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AdminApiService } from '../admin-api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-change-device-task',
  templateUrl: './change-device-task.component.html',
  styleUrls: ['./change-device-task.component.css']
})
export class ChangeDeviceTaskComponent implements OnInit {

  public owner_list = [];
  public selectedOwner = undefined;
  public ship_list = []
  public selectedShip = undefined;
  public machinery_list = [];
  public selectedMachinery = undefined;

  public selectedDeviceTask = undefined
  public all_device_task_list = []
  public blacklist_device_task = []

  public onChange: Subject<boolean>;

  public labels = [
    'ID',
    'Ship name',
    'Delete'
  ]

  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private AdminApi: AdminApiService,
  ) { }


  ngOnInit() {
    this.onChange = new Subject();
    this.exitModal()
    this.loadOwnerList()
  }

  exitModal() {
    this.router.events.subscribe((val) => {
      this.bsModalRef.hide();
    });
  }

  loadOwnerList() {
    this.AdminApi.getOwnersList().subscribe(response => {
      for (let item of response.owner_list) {
        this.owner_list.push({ 'owner_id': item[0], 'owner_name': item[1] })
      }
      this.selectedOwner = this.owner_list[0]
      this.loadAdminShipList()
    })
  }

  loadAdminShipList() {
    this.AdminApi.getShipsList(this.selectedOwner.owner_id).subscribe(response => {
      for (let item of response.ship_list) {
        this.ship_list.push({ 'ship_id': item[0], 'ship_name': item[1] })
      }
      this.selectedShip = this.ship_list[0]
      if (this.ship_list.length > 0) {
        this.loadMachineryList()
      }
    })
  }

  loadMachineryList() {
    this.AdminApi.getMachineryList(this.selectedShip.ship_id).subscribe(response => {
      for (let item of response.device_list) {
        this.machinery_list.push({ 'device_id': item[0], 'device_name': item[1] })
      }
      this.selectedMachinery = this.machinery_list[0];
      if (this.machinery_list.length > 0){
        this.loadDeviceTask()
      } 
    })
  }

  loadDeviceTask() {
    this.all_device_task_list = []
    this.blacklist_device_task = []
    this.AdminApi.getDeviceTaskList(this.selectedMachinery.device_id).subscribe(response => {
      for (let device_task of response.black_list) {
        this.blacklist_device_task.push({ 'device_task_id': device_task[0], 'device_task_name': device_task[1] })
      }
      for (let device_task of response.other_list) {
        this.all_device_task_list.push({ 'device_task_id': device_task[0], 'device_task_name': device_task[1] })
      }
      this.selectedDeviceTask = this.all_device_task_list[0]
    });
  }

  addTask() {
    this.AdminApi.addTaskToUserBlackList('device', { 'device_task_id': this.selectedDeviceTask.device_task_id, 'device_id': this.selectedMachinery.device_id }).subscribe(response => {
      if (response.status == 'success') {
        this.loadDeviceTask()
        this.onChange.next(true);
        this.selectedDeviceTask = {}
      }
    })
  }

  deleteTask(device_task_id) {
    this.AdminApi.deleteTaskToUserBlackList('device', { 'device_task_id': device_task_id, 'device_id': this.selectedMachinery.device_id }).subscribe(response => {
      if (response.status == 'success') {
        this.loadDeviceTask()
        this.onChange.next(true);
      }
    })
  }

  onChangeOwner() {
    this.machinery_list = []
    this.ship_list = []
    this.loadAdminShipList()
  }

  onChangeShip() {
    this.machinery_list = []
    this.loadMachineryList()
  }

  onChangeMachinery() {
    this.loadDeviceTask()
  }
}

import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from './login.service'
import { Md5 } from 'ts-md5/dist/md5';
import { AUTH } from '../auth/auth.models';
import { ToasterService } from 'angular2-toaster';


@Component({
  selector: 'infomarine-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private http: LoginService,
    private toastr: ToasterService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/module';
  }

  get f() { return this.loginForm.controls; }

  login() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }
    const json_list = {
      login: this.f.username.value,
      password: Md5.hashStr(this.f.username.value + this.f.password.value)
    }

    this.http.UserAuthorization(json_list).subscribe(response => {
      if (response.status == "success") {
        localStorage.removeItem(AUTH);
        localStorage.setItem(AUTH, response.session_id);
        this.toastr.pop('success', 'Success', 'Welcome in Engine Condition Monitoring App');
        this.router.navigate(['/modules']);
      } else if (response.status == "block"){
        this.toastr.pop('error', 'Error', 'Please contact the administrator (patryk.k@info-marine.com)');
      } else {
        this.toastr.pop('error', 'Error', 'Incorrect login or password');
      }

    });
  }
}

import { Component, OnInit, EventEmitter } from "@angular/core";
import { ChartOptions } from "chart.js";
import * as ChartAnnotation from "chartjs-plugin-annotation";
import { Subscription } from "rxjs";
import { CylinderBalanceApiService } from "./cylinder-balance-api.service";
import { CylinderBalance } from "./cylinder-balance.model";
import { Router, ActivatedRoute } from "@angular/router";
import { PagesService } from "../pages.service";
import { AuthService } from "../../auth/auth.service";

@Component({
  selector: "app-cylinder-balance",
  templateUrl: "./cylinder-balance.component.html",
  styleUrls: ["./cylinder-balance.component.css"],
})
export class CylinderBalanceComponent implements OnInit {
  title;
  CylinderBalanceListSubs: Subscription;
  CylinderBalanceList: CylinderBalance[];
  resultjson;
  barChartData;
  chartReady;
  barChartLabels;
  barChartOptions;

  public isAdmin = undefined;
  public ownersListSubs: Subscription;
  public exampleTable = undefined;
  public exampleTempTable = undefined;
  public pointsList = [];
  public pointsTempList = [];

  public parametersList = [];
  public parametersTempList = [];

  public owner_list = [];
  public selectedOwner = undefined;
  public ship_list = [];
  public selectedShip = undefined;
  public raport_list = [];
  public selectedRaportNumber = undefined;
  public machinery_list = [];
  public selectedMachinery = undefined;
  public task_list = [];
  public selectedTask = undefined;
  public chart_type_list = [
    { chart_type_id: "absolute", chart_type_name: "Absolute" },
    { chart_type_id: "relative_percent", chart_type_name: "Relative percent" },
    {
      chart_type_id: "relative_constant ",
      chart_type_name: "Relative constant",
    },
  ];
  public selectedChartType = undefined;
  public performance = [];
  public fire_oder = undefined;

  public measurement_date = undefined;

  onclick: EventEmitter<any> = new EventEmitter();

  constructor(
    private CylinderBalanceApi: CylinderBalanceApiService,
    private AuthServiceApi: AuthService,
    private http: PagesService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public barChartType = "bar";
  public barChartLegend = false;
  public colorlist;
  public vallist = [];
  public name;
  public labels;

  ngOnInit() {
    this.checkAdmin();
    localStorage.removeItem("task_device_list");
    localStorage.removeItem("pointCheck");
    if (localStorage.chart_type === undefined) {
      const chartTypeIndex = this.chart_type_list.findIndex(
        (task) => task.chart_type_id === "relative_percent"
      );

      if (chartTypeIndex !== -1) {
        this.selectedChartType = this.chart_type_list[chartTypeIndex];

        localStorage.setItem(
          "chart_type",
          JSON.stringify(this.selectedChartType)
        );
      } else {
        this.selectedChartType = this.chart_type_list[0];

      }
    } else {
      this.selectedChartType = JSON.parse(localStorage.chart_type);
    }
  }

  ngOnDestroy() {
    if (this.CylinderBalanceListSubs) {
      this.CylinderBalanceListSubs.unsubscribe();
    }
  }
  loadOwnerList() {
    if (localStorage.owner_list === undefined) {
      this.http.getOwnersList().subscribe((response) => {
        for (let item of response.owner_list) {
          this.owner_list.push({ owner_id: item[0], owner_name: item[1] });
        }
        localStorage.setItem("owner_list", JSON.stringify(this.owner_list));
        this.selectedOwner = this.owner_list[0];
        localStorage.setItem("owner", JSON.stringify(this.selectedOwner));
        this.loadAdminShipList();
      });
    } else {
      this.owner_list = JSON.parse(localStorage.owner_list);
      if (localStorage.owner === undefined) {
        this.selectedOwner = this.owner_list[0];
        localStorage.setItem("owner", JSON.stringify(this.selectedOwner));
      } else {
        this.selectedOwner = JSON.parse(localStorage.owner);
      }
      this.loadAdminShipList();
    }
  }

  loadAdminShipList() {
    if (this.route.snapshot.paramMap["params"]["ship_id"]) {
      this.http
        .getAdminShipsList(this.selectedOwner.owner_id)
        .subscribe((response) => {
          for (let item of response.ship_list) {
            this.ship_list.push({ ship_id: item[0], ship_name: item[1] });
          }
          localStorage.setItem("ship_list", JSON.stringify(this.ship_list));
          this.http
            .getShipName(this.route.snapshot.paramMap["params"]["ship_id"])
            .subscribe((response2) => {
              this.selectedShip = {
                ship_id: this.route.snapshot.paramMap["params"]["ship_id"],
                ship_name: response2,
              };
              localStorage.setItem("ship", JSON.stringify(this.selectedShip));
              if (this.ship_list.length > 0) {
                this.loadMachineryList();
              }
            });
        });
    } else {
      if (localStorage.ship_list === undefined) {
        this.http
          .getAdminShipsList(this.selectedOwner.owner_id)
          .subscribe((response) => {
            for (let item of response.ship_list) {
              this.ship_list.push({ ship_id: item[0], ship_name: item[1] });
            }
            localStorage.setItem("ship_list", JSON.stringify(this.ship_list));
            this.selectedShip = this.ship_list[0];
            localStorage.setItem("ship", JSON.stringify(this.selectedShip));
            if (this.ship_list.length > 0) {
              this.loadMachineryList();
            }
          });
      } else {
        this.ship_list = JSON.parse(localStorage.ship_list);
        if (localStorage.ship === undefined) {
          this.selectedShip = this.ship_list[0];
          localStorage.setItem("ship", JSON.stringify(this.selectedShip));
        } else {
          this.selectedShip = JSON.parse(localStorage.ship);
        }
        if (this.ship_list.length > 0) {
          this.loadMachineryList();
        }
      }
    }
  }

  LoadUserShipList() {
    if (this.route.snapshot.paramMap["params"]["ship_id"]) {
      this.http.getUserShipsList().subscribe((response) => {
        for (let item of response.ship_list) {
          this.ship_list.push({ ship_id: item[0], ship_name: item[1] });
        }
        localStorage.setItem("ship_list", JSON.stringify(this.ship_list));
        this.http
          .getShipName(this.route.snapshot.paramMap["params"]["ship_id"])
          .subscribe((response2) => {
            this.selectedShip = {
              ship_id: this.route.snapshot.paramMap["params"]["ship_id"],
              ship_name: response2,
            };
            localStorage.setItem("ship", JSON.stringify(this.selectedShip));
            if (this.ship_list.length > 0) {
              this.loadMachineryList();
            }
          });
      });
    } else {
      if (localStorage.ship_list === undefined) {
        this.http.getUserShipsList().subscribe((response) => {
          for (let item of response.ship_list) {
            this.ship_list.push({ ship_id: item[0], ship_name: item[1] });
          }
          localStorage.setItem("ship_list", JSON.stringify(this.ship_list));
          this.selectedShip = this.ship_list[0];
          localStorage.setItem("ship", JSON.stringify(this.selectedShip));
          if (this.ship_list.length > 0) {
            this.loadMachineryList();
          }
        });
      } else {
        this.ship_list = JSON.parse(localStorage.ship_list);
        if (localStorage.ship === undefined) {
          this.selectedShip = this.ship_list[0];
          localStorage.setItem("ship", JSON.stringify(this.selectedShip));
        } else {
          this.selectedShip = JSON.parse(localStorage.ship);
        }
        if (this.ship_list.length > 0) {
          this.loadMachineryList();
        }
      }
    }
  }

  loadMachineryList() {
    if (this.route.snapshot.paramMap["params"]["device_id"]) {
      this.http
        .getMachineryList(this.selectedShip.ship_id)
        .subscribe((response) => {
          for (let item of response.device_list) {
            this.machinery_list.push({
              device_id: item[0],
              device_name: item[1],
            });
          }
          localStorage.setItem(
            "machinery_list",
            JSON.stringify(this.machinery_list)
          );
          this.http
            .getDeviceName(this.route.snapshot.paramMap["params"]["device_id"])
            .subscribe((response) => {
              this.selectedMachinery = {
                device_id: this.route.snapshot.paramMap["params"]["device_id"],
                device_name: response,
              };
              localStorage.setItem(
                "device",
                JSON.stringify(this.selectedMachinery)
              );
              if (this.machinery_list.length > 0) {
                this.loadRaportList();
              }
            });
        });
    } else {
      if (localStorage.machinery_list === undefined) {
        this.http
          .getMachineryList(this.selectedShip.ship_id)
          .subscribe((response) => {
            for (let item of response.device_list) {
              this.machinery_list.push({
                device_id: item[0],
                device_name: item[1],
              });
            }
            localStorage.setItem(
              "machinery_list",
              JSON.stringify(this.machinery_list)
            );
            this.selectedMachinery = this.machinery_list[0];
            localStorage.setItem(
              "device",
              JSON.stringify(this.selectedMachinery)
            );
            if (this.machinery_list.length > 0) {
              this.loadRaportList();
            }
          });
      } else {
        this.machinery_list = JSON.parse(localStorage.machinery_list);
        if (localStorage.device === undefined) {
          this.selectedMachinery = this.machinery_list[0];
          localStorage.setItem(
            "device",
            JSON.stringify(this.selectedMachinery)
          );
        } else {
          this.selectedMachinery = JSON.parse(localStorage.device);
        }
        this.loadRaportList();
      }
    }
  }

  loadRaportList() {
    if (localStorage.raport_list === undefined) {
      this.http
        .getRaportList(this.selectedMachinery.device_id)
        .subscribe((response) => {
          for (let item of response.raport_list) {
            this.raport_list.push({ raport_number: item[0] });
          }
          localStorage.setItem("raport_list", JSON.stringify(this.raport_list));
          this.selectedRaportNumber = this.raport_list[0];
          localStorage.setItem(
            "report_number",
            JSON.stringify(this.selectedRaportNumber)
          );
          this.loadTaskList();
          this.loadParametersTable(
            this.selectedMachinery.device_id,
            this.selectedRaportNumber.raport_number
          );
          this.loadTable()
          this.loadPerformanceReports(
            this.selectedMachinery.device_id,
            this.selectedRaportNumber.raport_number
          );
        });
    } else {
      this.raport_list = JSON.parse(localStorage.raport_list);
      if (localStorage.report_number === undefined) {
        this.selectedRaportNumber = this.raport_list[0];
        localStorage.setItem(
          "report_number",
          JSON.stringify(this.selectedRaportNumber)
        );
      } else {
        this.selectedRaportNumber = JSON.parse(localStorage.report_number);
      }
      this.loadTaskList();
      this.loadParametersTable(
        this.selectedMachinery.device_id,
        this.selectedRaportNumber.raport_number
      );
      this.loadTable()
      this.loadPerformanceReports(
        this.selectedMachinery.device_id,
        this.selectedRaportNumber.raport_number
      );
    }
  }

  loadTaskList() {
    if (
      this.route.snapshot.paramMap["params"]["device_id"] &&
      this.route.snapshot.paramMap["params"]["task_id"]
    ) {
      var device_id = this.route.snapshot.paramMap["params"]["device_id"];
      var task_id = this.route.snapshot.paramMap["params"]["task_id"];
      this.CylinderBalanceApi.getAllName(device_id, task_id).subscribe(
        (response) => {
          var device_name = response.device_name;
          var task_name = response.task_name;

          this.selectedRaportNumber = {
            raport_number:
              this.route.snapshot.paramMap["params"]["raport_number"],
          };
          this.selectedMachinery = {
            device_id: device_id,
            device_name: device_name,
          };
          this.selectedTask = { task_id: task_id, task_name: task_name };
         // this.selectedChartType = this.selectedTask.chart_type_id;

          this.http
            .getPointTaskList(
              this.selectedRaportNumber.raport_number,
              this.selectedMachinery.device_id
            )
            .subscribe((response) => {
              for (let item of response.task_list) {
                this.task_list.push({
                  task_id: item[0],
                  task_name: item[1],
                  chart_type_id: item[2],
                  chart_type_name: item[3],
                });
              }
              localStorage.setItem(
                "task_point_list",
                JSON.stringify(this.task_list)
              );
              localStorage.setItem("task", JSON.stringify(this.selectedTask));

              this.loadChart(
                this.selectedMachinery.device_id,
                this.selectedRaportNumber.raport_number,
                this.selectedTask.task_id,
                this.selectedChartType.chart_type_id
              );
            });
        }
      );
    } else {
      if (localStorage.task_point_list === undefined) {
        this.http
          .getPointTaskList(
            this.selectedRaportNumber.raport_number,
            this.selectedMachinery.device_id
          )
          .subscribe((response) => {
            for (let item of response.task_list) {
              this.task_list.push({
                task_id: item[0],
                task_name: item[1],
                chart_type_id: item[2],
                chart_type_name: item[3],
              });
            }
            localStorage.setItem(
              "task_point_list",
              JSON.stringify(this.task_list)
            );
            const temperatureTaskIndex = this.task_list.findIndex(
              (task) => task.task_name === "Temperature"
            );
            if (temperatureTaskIndex !== -1) {
              this.selectedTask = this.task_list[temperatureTaskIndex];
            } else {
              this.selectedTask = this.task_list[0];
            }

            localStorage.setItem("task", JSON.stringify(this.selectedTask));
           // this.selectedChartType = this.selectedTask.chart_type_id;

            localStorage.setItem("task", JSON.stringify(this.selectedTask));
            if (this.task_list.length > 0) {
              this.loadChart(
                this.selectedMachinery.device_id,
                this.selectedRaportNumber.raport_number,
                this.selectedTask.task_id,
                this.selectedChartType.chart_type_id
              );
            } else {
              this.vallist = [];
            }
          });
      } else {
        this.task_list = JSON.parse(localStorage.task_point_list);
        if (localStorage.task === undefined) {
          this.selectedTask = this.task_list[0];
        //  this.selectedChartType = this.selectedTask.chart_type_id;

          localStorage.setItem("task", JSON.stringify(this.selectedTask));
        } else {
          this.selectedTask = JSON.parse(localStorage.task);
        }
        if (this.task_list.length > 0) {
          this.loadChart(
            this.selectedMachinery.device_id,
            this.selectedRaportNumber.raport_number,
            this.selectedTask.task_id,
            this.selectedChartType.chart_type_id
          );
        } else {
          this.vallist = [];
        }
      }
    }
  }

  onChangeOwner() {
    localStorage.removeItem("owner");
    localStorage.removeItem("ship");
    localStorage.removeItem("device");
    localStorage.removeItem("report_number");
    localStorage.removeItem("task");
    localStorage.removeItem("point");
    localStorage.removeItem("ship_list");
    localStorage.removeItem("task_point_list");
    localStorage.removeItem("point_list");
    localStorage.removeItem("raport_list");
    localStorage.removeItem("machinery_list");
    localStorage.removeItem("oil_raport_list");
    localStorage.removeItem("oil_report_number");
    localStorage.setItem("owner", JSON.stringify(this.selectedOwner));
    this.machinery_list = [];
    this.raport_list = [];
    this.task_list = [];
    this.ship_list = [];
    this.loadAdminShipList();
  }

  onChangeShip() {
    localStorage.removeItem("ship");
    localStorage.removeItem("device");
    localStorage.removeItem("report_number");
    localStorage.removeItem("task");
    localStorage.removeItem("point");
    localStorage.removeItem("task_point_list");
    localStorage.removeItem("point_list");
    localStorage.removeItem("raport_list");
    localStorage.removeItem("machinery_list");
    localStorage.removeItem("oil_raport_list");
    localStorage.removeItem("oil_report_number");
    localStorage.setItem("ship", JSON.stringify(this.selectedShip));
    this.machinery_list = [];
    this.raport_list = [];
    this.task_list = [];
    this.loadMachineryList();
  }

  onChangeMachinery() {
    localStorage.removeItem("device");
    localStorage.removeItem("report_number");
    localStorage.removeItem("task");
    localStorage.removeItem("point");
    localStorage.removeItem("task_point_list");
    localStorage.removeItem("point_list");
    localStorage.removeItem("raport_list");
    localStorage.removeItem("oil_raport_list");
    localStorage.removeItem("oil_report_number");
    localStorage.setItem("device", JSON.stringify(this.selectedMachinery));
    this.raport_list = [];
    this.task_list = [];
    this.loadRaportList();
  }

  onChangeRaport() {
    localStorage.removeItem("report_number");
    localStorage.removeItem("task");
    localStorage.removeItem("point");
    localStorage.removeItem("task_point_list");
    localStorage.removeItem("point_list");
    localStorage.setItem(
      "report_number",
      JSON.stringify(this.selectedRaportNumber)
    );
    this.task_list = [];
    this.loadTaskList();
    this.loadParametersTable(
      this.selectedMachinery.device_id,
      this.selectedRaportNumber.raport_number
    );
    this.loadTable()
    this.loadPerformanceReports(
      this.selectedMachinery.device_id,
      this.selectedRaportNumber.raport_number
    );
  }

  onChangeTask() {
    localStorage.removeItem("task");
    localStorage.removeItem("point_list");
    localStorage.setItem("task", JSON.stringify(this.selectedTask));
    const index = this.task_list.findIndex(
      (task) => task.task_id === this.selectedTask.task_id
    );
    if (index !== -1) {
      this.selectedChartType = this.task_list[index];
    } else {
    }

    this.loadChart(
      this.selectedMachinery.device_id,
      this.selectedRaportNumber.raport_number,
      this.selectedTask.task_id,
      this.selectedChartType.chart_type_id
    );
  }

  onChangeChartType() {
    localStorage.removeItem("chart_type");
    localStorage.setItem("chart_type", JSON.stringify(this.selectedChartType));
    this.loadChart(
      this.selectedMachinery.device_id,
      this.selectedRaportNumber.raport_number,
      this.selectedTask.task_id,
      this.selectedChartType.chart_type_id
    );
  }

  loadTable() {
    this.http.getParametersTable(this.selectedMachinery.device_id, this.selectedRaportNumber.raport_number)
        .subscribe(response => {
            this.exampleTempTable = response,
                this.pointsTempList = response.points,
                this.parametersTempList = response.parameters
                
      
        });
}

  loadChart(device_id, raport_number, task_id, chart_type_id) {
    this.chartReady = false;
    this.CylinderBalanceListSubs = this.CylinderBalanceApi.getCylinderBalance(
      device_id,
      raport_number,
      task_id,
      chart_type_id
    ).subscribe((res) => {
      (this.resultjson = res),
        (this.colorlist = res["backgroundColor"]),
        (this.vallist = res["data"]),
        (this.labels = res["pointNames"]),
        (this.name = res["label"]);
      (this.barChartLabels = this.labels),
        (this.barChartOptions = {
          tooltips: {},
          scaleShowVerticalLines: false,
          responsive: true,
          title: {
            display: true,
            text: this.name,
          },
          annotation: {
            annotations: [
              {
                drawTime: "afterDatasetsDraw",
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: res.top,
                borderColor: "tomato",
                borderWidth: 1,
              },
              {
                drawTime: "afterDatasetsDraw",
                type: "line",
                mode: "horizontal",
                scaleID: "y-axis-0",
                value: res.bot,
                borderColor: "tomato",
                borderWidth: 1,
              },
            ],
          } as ChartOptions,

          plugins: [ChartAnnotation],
          maintainAspectRatio: false,
          aspectRatio: 1,
        });

      this.barChartData = [
        {
          data: this.vallist,
          backgroundColor: this.colorlist,
          label: this.selectedTask.task_name + " [" + res["unit"] + "]",
        },
      ];
    });
  }
  loadParametersTable(device_id, raport_number) {
    this.ownersListSubs = this.CylinderBalanceApi.getOwners(
      device_id,
      raport_number
    ).subscribe((res) => {
      (this.exampleTable = res),
        (this.pointsList = res.points),
        (this.parametersList = res.parameters);
    }, console.error);
  }

  loadPerformanceReports(device_id, raport_number) {
    this.CylinderBalanceApi.getPerformanceReports(
      device_id,
      raport_number
    ).subscribe((response) => {
      this.measurement_date = response.measurement_date;
      this.performance = response.parm;
      var i = 0;
      for (let res of response.parm) {
        this.performance[i]["percent"] = (
          (res.value / res.conf.max_load) *
          100
        ).toFixed(1);
        i = i + 1;
      }
      var obj = response.parm[0].conf.comb_points;
      var result = Object.keys(obj).map((key) => [key, obj[key]["phasing"]]);
      var sort = this.insertionSort(result);
      this.fire_oder = "";
      var check_value = sort.every((val, i, arr) => val === arr[0]);
      if (check_value == false) {
        for (let fire of sort) {
          if (fire[0].includes("Right") || fire[0].includes("Left")) {
            this.fire_oder +=
              this.checkIfNumberisGreaterThanNine(fire[0]) +
              fire[0].slice(0, 1) +
              "-";
          } else if (fire[0].slice(0, 1) == "A" || fire[0].slice(0, 1) == "B") {
            this.fire_oder +=
              this.checkIfNumberisGreaterThanNine(fire[0]) +
              fire[0].slice(0, 1) +
              "-";
          } else {
            this.fire_oder +=
              this.checkIfNumberisGreaterThanNine(fire[0]) + "-";
          }
        }
        this.fire_oder = this.fire_oder.slice(0, -1);
      }
    });
  }

  isNumeric(s) {
    return !isNaN(s - parseFloat(s));
  }

  checkIfNumberisGreaterThanNine(string) {
    if (this.isNumeric(string.slice(-2, -1))) {
      return string.slice(-2);
    } else {
      return string.slice(-1);
    }
  }

  // Sortowanie przez wstawianie
  insertionSort(inputArr) {
    let n = inputArr.length;
    for (let i = 1; i < n; i++) {
      let current = inputArr[i];
      let value = inputArr[i][1];
      let j = i - 1;
      while (j > -1 && value < inputArr[j][1]) {
        inputArr[j + 1] = inputArr[j];
        j--;
      }
      inputArr[j + 1] = current;
    }
    return inputArr;
  }

  clickElement(e) {
    if (e.active.length > 0) {
      var point_name = e.active[0]._view.label;
      this.http
        .getPointId(
          this.selectedRaportNumber.raport_number,
          this.selectedMachinery.device_id,
          this.selectedTask.task_id,
          point_name
        )
        .subscribe((response) => {
          var point_id = response.point_id;
          this.router.navigate([
            "/modules/numeric-reference/" +
              this.selectedRaportNumber.raport_number +
              "/" +
              this.selectedMachinery.device_id +
              "/" +
              this.selectedTask.task_id +
              "/" +
              point_id +
              "/" +
              "true",
          ]);
        });
    }
  }

  rediretToNumericReference(task_id, point_id) {
    this.router.navigate([
      "/modules/numeric-reference/" +
        this.selectedRaportNumber.raport_number +
        "/" +
        this.selectedMachinery.device_id +
        "/" +
        task_id +
        "/" +
        point_id +
        "/" +
        "true",
    ]);
  }

  rediretToNumericReferenceNotPoint(task_id) {
    this.router.navigate([
      "/modules/numeric-reference/" +
        this.selectedRaportNumber.raport_number +
        "/" +
        this.selectedMachinery.device_id +
        "/" +
        task_id +
        "/" +
        "false",
    ]);
  }

  clickChangeTask(selectedTask) {
    this.selectedTask = selectedTask;
    this.onChangeTask();
  }

  checkAdmin() {
    this.AuthServiceApi.isAdmin().subscribe((response) => {
      this.isAdmin = response.isadmin;
      if (this.isAdmin) {
        this.loadOwnerList();
      } else {
        this.LoadUserShipList();
      }
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminApiService } from '../admin-api.service';
import { Md5 } from 'ts-md5/dist/md5';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {

  public adminUser = false;
  
  public addUserForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private AdminApi: AdminApiService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.addUserForm = this.formBuilder.group({
      full_name: ['', Validators.required],
      login: ['', Validators.required],
      password: ['', Validators.required]
  });
    this.adminUser = false;
  }

  get f() { return this.addUserForm.controls; }

  checkAdmin() {
    if (this.adminUser) {
      this.adminUser = false;
    } else {
      this.adminUser = true;
    }
  }

  addUser() {
    if (this.addUserForm.invalid) {
      return ;
  }
  const json_list = { 
    full_name: this.f.full_name.value,
    login: this.f.login.value, 
    password: Md5.hashStr(this.f.login.value + this.f.password.value),
    admin_user: this.adminUser
  }
  this.AdminApi.addUser(json_list).subscribe(response => {
    if (response.status == 'success'){
      this.router.navigate(['/modules/admin']);
    }
  })
  }

}

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Observable, EMPTY, throwError, } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable()

export class NumericReferenceApiService {

  constructor(private http: HttpClient) {
    
  }  

  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  getNumericReference(device_id, raport_number, task_id, point_id, point_bool): Observable<any> {
    return this.http.get<any>(`numeric-reference/` + device_id + '/' + raport_number + '/' + task_id + '/' + point_id + '/' + point_bool).pipe(
      map(response => {return response;})
      )
  };

  getAllName(device_id, task_id, point_id): Observable<any> {
    return this.http.get<any>(`get_name_numeric_reference/` + device_id + '/' + task_id + '/' + point_id).pipe(
      map(response => {return response;})
      )
  };

};

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable} from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()

export class LoginService {

  constructor(private http: HttpClient) {   
  }  

  UserAuthorization(json_list): Observable<any> {
  return this.http.post<any>(`check_password`, json_list).pipe(
    map(response => {
        return response;})
    )
};

};
import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth/auth.service'
import {PagesService} from "../pages.service";


@Component({
  selector: 'app-oil-analysis',
  templateUrl: './oil-analysis.component.html',
  styleUrls: ['./oil-analysis.component.css']
})
export class OilAnalysisComponent implements OnInit {

  public isAdmin = undefined;

  public owner_list = [];
  public selectedOwner = undefined;

  public ship_list = [];
  public selectedShip = undefined;

  public machinery_list = [];
  public selectedMachinery = undefined;

  public oil_raport_list = [];
  public selectedOilRaportNumber = undefined;

  public array_details = [];
  public array_params = [];

  constructor(
    private AuthServiceApi: AuthService,
    private http: PagesService,
  ) {

  }

  ngOnInit() {
    this.checkAdmin();

  }

  loadOwnerList() {
    if (localStorage.owner_list === undefined) {
      this.http.getOwnersList().subscribe(response => {
        for (let item of response.owner_list) {
          this.owner_list.push({'owner_id': item[0], 'owner_name': item[1]})
        }
        localStorage.setItem('owner_list', JSON.stringify(this.owner_list));
        this.selectedOwner = this.owner_list[0];
        localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
        this.loadAdminShipList()
      })
    } else {
      this.owner_list = JSON.parse(localStorage.owner_list);
      if (localStorage.owner === undefined) {
        this.selectedOwner = this.owner_list[0];
        localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
      } else {
        this.selectedOwner = JSON.parse(localStorage.owner);
      }
      this.loadAdminShipList();
    }
  }

  loadAdminShipList() {
    if (localStorage.ship_list === undefined) {
      this.http.getAdminShipsList(this.selectedOwner.owner_id).subscribe(response => {
        for (let item of response.ship_list) {
          this.ship_list.push({'ship_id': item[0], 'ship_name': item[1]});
        }
        localStorage.setItem('ship_list', JSON.stringify(this.ship_list));
        this.selectedShip = this.ship_list[0];
        localStorage.setItem('ship', JSON.stringify(this.selectedShip));
        if (this.ship_list.length > 0) {
          this.loadMachineryList();
        }
      })
    } else {
      this.ship_list = JSON.parse(localStorage.ship_list);
      if (localStorage.ship === undefined) {
        this.selectedShip = this.ship_list[0];
        localStorage.setItem('ship', JSON.stringify(this.selectedShip));
      } else {
        this.selectedShip = JSON.parse(localStorage.ship);
      }
      if (this.ship_list.length > 0) {
        this.loadMachineryList();
      }
    }
  }


  LoadUserShipList() {
    if (localStorage.ship_list === undefined) {
      this.http.getUserShipsList().subscribe(response => {
        for (let item of response.ship_list) {
          this.ship_list.push({'ship_id': item[0], 'ship_name': item[1]});
        }
        localStorage.setItem('ship_list', JSON.stringify(this.ship_list));
        this.selectedShip = this.ship_list[0];
        localStorage.setItem('ship', JSON.stringify(this.selectedShip));
        if (this.ship_list.length > 0) {
          this.loadMachineryList();
        }
      })
    } else {
      this.ship_list = JSON.parse(localStorage.ship_list);
      if (localStorage.ship === undefined) {
        this.selectedShip = this.ship_list[0];
        localStorage.setItem('ship', JSON.stringify(this.selectedShip));
      } else {
        this.selectedShip = JSON.parse(localStorage.ship);
      }
      if (this.ship_list.length > 0) {
        this.loadMachineryList();
      }
    }
  }

  loadMachineryList() {
    if (localStorage.machinery_list === undefined) {
      this.http.getMachineryList(this.selectedShip.ship_id).subscribe(response => {
        for (let item of response.device_list) {
          this.machinery_list.push({'device_id': item[0], 'device_name': item[1]})
        }
        localStorage.setItem('machinery_list', JSON.stringify(this.machinery_list));
        this.selectedMachinery = this.machinery_list[0];
        localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
        if (this.machinery_list.length > 0) {
          this.loadOilRaportList();
        }
      })

    } else {
      this.machinery_list = JSON.parse(localStorage.machinery_list)
      if (localStorage.device === undefined) {
        this.selectedMachinery = this.machinery_list[0];
        localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
      } else {
        this.selectedMachinery = JSON.parse(localStorage.device)
      }
      if (this.machinery_list.length > 0) {
        this.loadOilRaportList();
      }

    }

  }


  loadOilRaportList() {
    if (localStorage.oil_raport_list === undefined) {
      this.http.getOilRaportList(this.selectedMachinery.device_id).subscribe(response => {
        for (let item of response.oil_raport_list) {
          this.oil_raport_list.push({'raport_number': item[0]});
        }
        localStorage.setItem('oil_raport_list', JSON.stringify(this.oil_raport_list));
        this.selectedOilRaportNumber = this.oil_raport_list[0];
        localStorage.setItem('oil_report_number', JSON.stringify(this.selectedOilRaportNumber));
        if (this.oil_raport_list.length > 0) {
          this.loadOilRaportDetails();
        }

      });
    } else {
      this.oil_raport_list = JSON.parse(localStorage.oil_raport_list)
      if (localStorage.oil_report_number === undefined) {
        this.selectedOilRaportNumber = this.oil_raport_list[0];
        localStorage.setItem('oil_report_number', JSON.stringify(this.selectedOilRaportNumber));
      } else {
        this.selectedOilRaportNumber = JSON.parse(localStorage.oil_report_number);
      }
      if (this.oil_raport_list.length > 0) {
        this.loadOilRaportDetails();
      }
    }
  };

  loadOilRaportDetails() {
    this.http.loadOilRaportDetails(this.selectedOilRaportNumber.raport_number).subscribe(response => {
      this.array_details = response.array_details;
      this.array_params = response.array_params;
    });

  }


  onChangeOwner() {
    localStorage.removeItem('owner');
    localStorage.removeItem('ship');
    localStorage.removeItem('device');
    localStorage.removeItem('report_number');
    localStorage.removeItem('task');
    localStorage.removeItem('point');
    localStorage.removeItem('ship_list');
    localStorage.removeItem('task_point_list');
    localStorage.removeItem('point_list');
    localStorage.removeItem('raport_list');
    localStorage.removeItem('machinery_list');

    localStorage.removeItem('oil_raport_list');
    localStorage.removeItem('oil_report_number');
    localStorage.removeItem('oil_raport_list');
    localStorage.removeItem('oil_report_number');

    localStorage.setItem('owner', JSON.stringify(this.selectedOwner));
    this.machinery_list = [];
    this.ship_list = [];
    this.oil_raport_list = [];
    this.array_details = [];
    this.array_params = [];
    this.loadAdminShipList();
  }

  onChangeShip() {
    localStorage.removeItem('ship');
    localStorage.removeItem('device');
    localStorage.removeItem('report_number');
    localStorage.removeItem('task');
    localStorage.removeItem('point');
    localStorage.removeItem('task_point_list');
    localStorage.removeItem('point_list');
    localStorage.removeItem('raport_list');
    localStorage.removeItem('machinery_list');

    localStorage.removeItem('oil_raport_list');
    localStorage.removeItem('oil_report_number');

    localStorage.setItem('ship', JSON.stringify(this.selectedShip));
    this.machinery_list = [];
    this.oil_raport_list = [];
    this.array_details = [];
    this.array_params = [];
    this.loadMachineryList();
  }

  onChangeMachinery() {
    localStorage.removeItem('device');
    localStorage.removeItem('report_number');
    localStorage.removeItem('task');
    localStorage.removeItem('point');
    localStorage.removeItem('task_point_list');
    localStorage.removeItem('task_device_list');
    localStorage.removeItem('point_list');
    localStorage.removeItem('raport_list');
    localStorage.removeItem('oil_raport_list');

    localStorage.removeItem('oil_raport_list');
    localStorage.removeItem('oil_report_number');

    localStorage.setItem('device', JSON.stringify(this.selectedMachinery));
    this.oil_raport_list = [];
    this.array_details = [];
    this.array_params = [];
    this.loadOilRaportList();
  }

  onChangeOilRaport() {
    localStorage.removeItem('oil_report_number');
    localStorage.setItem('oil_report_number', JSON.stringify(this.selectedOilRaportNumber));
    this.loadOilRaportDetails();
  }


  checkAdmin() {
    this.AuthServiceApi.isAdmin().subscribe(response => {
      this.isAdmin = response.isadmin;
      if (this.isAdmin) {
        this.loadOwnerList();
      } else {
        this.LoadUserShipList();
      }
    });
  }


}

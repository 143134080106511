import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../pages.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dashboard-details',
  templateUrl: './dashboard-details.component.html',
  styleUrls: ['./dashboard-details.component.css']
})
export class DashboardDetailsComponent implements OnInit {


  public ship_id; any;

  public ship_name: any;

  public device_dashboard_list = [];

  readonly CHART_TYPE = 'doughnut';

  constructor(
    public bsModalRef: BsModalRef,
    private http: PagesService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.loadDashboard()
  }

  loadDashboard() {
    this.http.getDashboardForOneShip(this.ship_id).subscribe(response => {
      this.device_dashboard_list = []
      for (let device_data of response) {
        let sets = [{
          data: [device_data.donut1['A'], device_data.donut1['B'], device_data.donut1['C']],
          backgroundColor: ['#008000a1', '#ffff008f', '#ff0000ad'],
        },
        {
          data: [device_data.donut2['green'], device_data.donut2['yellow'], device_data.donut2['red']],
          backgroundColor: ['#008000a1', '#ffff008f', '#ff0000ad'],
        }];

        
        if (device_data.donut3['feedback'] === 0 && device_data.donut3['delivered'] === 0 && device_data.donut3['no-feedback'] === 0) {
          sets.push({
            data: [1], 
            backgroundColor: ['#008000a1'], 
          });
        } else {
          sets.push({
            data: [device_data.donut3['feedback'], device_data.donut3['delivered'], device_data.donut3['no-feedback']],
            backgroundColor: ['#008000a1', '#ffff008f', '#ff0000ad'],
          });
        }
        let remarkLabel = 'Feedback';
        if (device_data.donut3['feedback'] === 0 && device_data.donut3['delivered'] === 0 && device_data.donut3['no-feedback'] === 0) {
          remarkLabel = 'No remark send';
        }
        let data = {
          labels: [['Remarks Type A', 'Remarks Type B', 'Remarks Type C'], ['Days since the last measurement', 'Days since the last measurement', 'Days since the last measurement'], [remarkLabel, 'Delivered', 'No Feedback']],
          datasets: sets
        };
        let options = {
          plugins: {
            labels: [
              {
                render: 'value',
                fontColor: '#000',
                fontSize: 14,
                arc: false,
              }
            ],
          },
          percentageInnerCutout: 10,
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          tooltips: {
            callbacks: {
              label: function (item, data) {
                 
                  if (data.datasets[item.datasetIndex].data.toString() === '1') {
                      return 'No remark send';
                  } else {
                      return data.labels[item.datasetIndex][item.index] + ': ' + data.datasets[item.datasetIndex].data[item.index];
                  }
              },
              beforeBody: function (item, data) {
                  return data.datasets[item[0].datasetIndex].label;
              }
          }
          },
          title: {
            display: true,
            text: device_data.device_name,
            fontSize: 24
          }
        };
        this.device_dashboard_list.push({ 'data': data, 'options': options, 'device_id': device_data.device_id, 'device_name': device_data.device_name })
      }
    })
  }

  clickElement(event, device_id) {
    if (event.length > 0) {
      if (event[0]._datasetIndex == 0 || event[0]._datasetIndex == 2) {
        this.router.navigate(['/modules/remarks/' + String(this.ship_id) + '/' + device_id]);
      } else {
        this.router.navigate(['/modules/cylinder-balance/' + String(this.ship_id) + '/' + device_id]);
      }
    } 
  };

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()

export class AdminApiService {

  constructor(
    private http: HttpClient) {

  }

  getUsers(): Observable<any> {
    return this.http.get<any>(`users`).pipe(
      map(response => {
        return response;
      })
    )
  };

  getUserInfo(user_id): Observable<any> {
    return this.http.get<any>(`user/` + user_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getShip(user_id): Observable<any> {
    return this.http.get<any>(`load_ship/` + user_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  addShipToUser(user_id, json_list): Observable<any> {
    return this.http.post<any>('add_ship/' + user_id, json_list).pipe(
      map(response => {
        return response;
      })
    )
  };

  deleteShipUser(user_id, json_list): Observable<any> {
    return this.http.post<any>('delete_ship/' + user_id, json_list).pipe(
      map(response => {
        return response;
      })
    )
  };

  addUser(json_list): Observable<any> {
    return this.http.post<any>('add_user', json_list).pipe(
      map(response => {
        return response;
      })
    )
  };

  deleteUser(json_list): Observable<any> {
    return this.http.post<any>('delete_user', json_list).pipe(
      map(response => {
        return response;
      })
    )
  };

  loadOwnersFromImDB(): Observable<any> {
    return this.http.get('get_im_owner_list').pipe(
      map(response => {
        return response;
      })
    )
  };

  loadShipsFromImDB(owner_id): Observable<any> {
    return this.http.get('get_im_ship_list/' + owner_id).pipe(
      map(response => {
        return response;
      })
    )
  };


  loadFromImDatabase(ship_id): Observable<any> {
    return this.http.post<any>('load_from_im_db/' + ship_id, {}).pipe(
      map(response => {
        return response;
      })
    )
  };


  updateFromImDatabase(ship_id): Observable<any> {
    return this.http.post<any>('update_from_im_db/' + ship_id, {}).pipe(
      map(response => {
        return response;
      })
    )
  };

  deleteFromNaviDatabase(ship_id): Observable<any> {
    return this.http.post<any>('delete_from_navi_db/' + ship_id, {}).pipe(
      map(response => {
        return response;
      })
    )
  };

  getOwnersList(): Observable<any> {
    return this.http.get<any>('get_owner_list').pipe(
      map(response => {
        return response;
      })
    )
  };

  getShipsList(owner_id): Observable<any> {
    return this.http.get<any>('get_admin_ship_list/' + owner_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getMachineryList(ship_id): Observable<any> {
    return this.http.get<any>('get_machinery_list/' + ship_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getReportListByShip(ship_id): Observable<any> {
    return this.http.get<any>('get_raport_list_by_ship/' + ship_id).pipe(
      map(response => {
        return response;
      })
    )
  };


  getPointList(device_id): Observable<any> {
    return this.http.get<any>('get_point_list_without_task/' + device_id).pipe(
      map(response => {
        return response;
      })
    )
  }

  getDeviceBlackTable(): Observable<any> {
    return this.http.get<any>(`load_blaktable_device_task`).pipe(
      map(response => {
        return response;
      })
    )
  };

  getPointBlackTable(): Observable<any> {
    return this.http.get<any>(`load_blaktable_point_task`).pipe(
      map(response => {
        return response;
      })
    )
  };

  getDeviceTaskList(device_id): Observable<any> {
    return this.http.get<any>(`load_blacklist_device_task/` + device_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getPointTaskList(device_id): Observable<any> {
    return this.http.get<any>(`load_blacklist_point_task/` + device_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  addTaskToUserBlackList(task_type, json_list): Observable<any> {
    return this.http.post<any>('add_task/' + task_type, json_list).pipe(
      map(response => {
        return response;
      })
    )
  };

  deleteTaskToUserBlackList(task_type, json_list): Observable<any> {
    return this.http.post<any>('delete_task/' + task_type, json_list).pipe(
      map(response => {
        return response;
      })
    )
  };


  addUserBlockDate(json_data): Observable<any> {
    return this.http.post<any>('add_block_user_date', json_data).pipe(
      map(response => {
        return response;
      })
    )
  };

  updateUser(json_data): Observable<any> {
    return this.http.post<any>('update_user', json_data).pipe(
      map(response => {
        return response;
      })
    )
  };

  setPassword(json_data): Observable<any> {
    return this.http.post<any>('set-password', json_data).pipe(
      map(response => {
        return response;
      })
    )
  };

  deleteReportFromNaviDatabase(report_pkey): Observable<any> {
    return this.http.post<any>('delete_report/' + report_pkey, {}).pipe(
      map(response => {
        return response;
      })
    )
  };

}

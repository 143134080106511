import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()

export class PagesService {

  constructor(private http: HttpClient) {
  }

  getRaportList(device_id): Observable<any> {
    return this.http.get<any>('get_raport_list/' + device_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getOilRaportList(device_id): Observable<any> {
    return this.http.get<any>('get_oil_raport_list/' + device_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  loadOilRaportDetails(oil_report_number): Observable<any> {
    return this.http.get<any>('get_oil_raport_details/' + oil_report_number).pipe(
      map(response => {
        return response;
      })
    )
  };

  getMachineryList(ship_id): Observable<any> {
    return this.http.get<any>('get_machinery_list/' + ship_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getPointTaskList(raport_number, device_id): Observable<any> {
    return this.http.get<any>(`get_point_task_list/` + raport_number + '/' + device_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getDeviceTaskList(raport_number, device_id): Observable<any> {
    return this.http.get<any>(`get_device_task_list/` + raport_number + '/' + device_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getPointList(raport_number, device_id, task_id = undefined): Observable<any> {
    return this.http.get<any>(`get_point_list/` + raport_number + '/' + device_id + '/' + task_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getList(raport_number, device_id, task_id = undefined): Observable<any> {
    return this.http.get<any>(`get_point_list_bearings_combined/` + raport_number + '/' + device_id + '/' + task_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getParametersTable(device_id, raport_number): Observable<any> {
    return this.http.get<any>(`parameters-table-mb/` + device_id + '/' + raport_number).pipe(
      map(response => {
        return response;
      })
    )
  };

  getBalanceChart(device_id, raport_number, task_id, chart_type_id): Observable<any> {
    return this.http.get<any>(`cylinder-balance-mb/` + device_id + '/' + raport_number + '/' + task_id + '/' + chart_type_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getPointId(raport_number, device_id, task_id, point_name): Observable<any> {
    return this.http.get<any>(`get_point_id/` + raport_number + '/' + device_id + '/' + task_id + '/' + point_name).pipe(
      map(response => {
        return response;
      })
    )
  };

  getDeviceTaskName(task_id): Observable<any> {
    return this.http.get<any>(`get_device_task_name/` + task_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getShipName(ship_id): Observable<any> {
    return this.http.get<any>(`get_ship_name/` + ship_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getDeviceName(device_id): Observable<any> {
    return this.http.get<any>(`get_device_name/` + device_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getOwnersList(): Observable<any> {
    return this.http.get<any>('get_owner_list').pipe(
      map(response => {
        return response;
      })
    )
  };

  getAdminShipsList(owner_id): Observable<any> {
    return this.http.get<any>('get_admin_ship_list/' + owner_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getUserShipsList(): Observable<any> {
    return this.http.get<any>('get_user_ship_list').pipe(
      map(response => {
        return response;
      })
    )
  };

  getRemarks(device_id, raport_number, isAdmin): Observable<any> {
    return this.http.get<any>('get_remarks/' + device_id + '/' + raport_number + '/' + isAdmin).pipe(
      map(response => {
        return response;
      })
    )
  };

  changeRemarkPointVisible(json_data): Observable<any> {
    return this.http.post<any>('change_remark_point_visible', json_data).pipe(
      map(response => {
        return response;
      })
    )
  };

  changeRemarkDeviceVisible(json_data): Observable<any> {
    return this.http.post<any>('change_remark_device_visible', json_data).pipe(
      map(response => {
        return response;
      })
    )
  };

  changeAllRemarkVisible(json_data): Observable<any> {
    return this.http.post<any>('change_all_remark_visible', json_data).pipe(
      map(response => {
        return response;
      })
    )
  };

  getAdminDashboard(owner_id): Observable<any> {
    return this.http.get<any>('get_admin_dashboard/' + owner_id).pipe(
      map(response => {
        return response;
      })
    )
  };

  getDashboard(): Observable<any> {
    return this.http.get<any>('get_dashboard').pipe(
      map(response => {
        return response;
      })
    )
  };

  getDashboardForOneShip(ship_id): Observable<any> {
    return this.http.get<any>('get_dashboard_for_one_ship/' + ship_id).pipe(
      map(response => {
        return response;
      })
    )
  };

};
import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Observable, EMPTY, throwError, } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable()

export class ChartDataApiService {

  constructor(private http: HttpClient) {
    
  }  

  erroHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

getChartData(point_id, report_number, selectedPointToSub): Observable<any> {
  return this.http.get<any>('chart-data/' + point_id + '/' + report_number + '/' + selectedPointToSub).pipe(
    map(response => {return response;})
    )

};

};
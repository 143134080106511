import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AdminApiService } from '../admin-api.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-change-ship',
  templateUrl: './change-ship.component.html',
  styleUrls: ['./change-ship.component.css']
})
export class ChangeShipComponent implements OnInit {

  public user = undefined
  public user_id = undefined
  public selectedShip = undefined
  public all_ship_list = []
  public user_ships = []

  public onChange: Subject<boolean>;

  public labels = [
    'ID',
    'Ship name',
    'Delete'
  ]

  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private AdminApi: AdminApiService,
  ) { }
  

  ngOnInit() {
    this.onChange = new Subject();
    this.exitModal()
    this.loadUserInfo()

  }

  loadUserInfo(){
    this.AdminApi.getUserInfo(this.user_id).subscribe(response =>{
      this.user  = response
      if (response){
        this.loadShip()
      }
    })
  }

  loadShip(){
    this.all_ship_list = []
    this.user_ships = []
    this.AdminApi.getShip(this.user_id).subscribe(response =>{
      for (let ship of response.user_ships){
        this.user_ships.push({'ship_id' : ship[0], 'ship_name' : ship[1]})
      }
      for (let ship of response.not_user_ships){
        this.all_ship_list.push({'ship_id' : ship[0], 'ship_name' : ship[1]})
      }
    });
  }

  addShip(){ 
    this.AdminApi.addShipToUser(this.user_id, {'ship_id' : this.selectedShip.ship_id}).subscribe(response =>{
      if (response.status == 'success'){
        this.loadUserInfo()
        this.onChange.next(true);
        this.selectedShip = {}

      }
    })
  }

  exitModal(){
    this.router.events.subscribe((val) => {
      this.bsModalRef.hide();
    });
  }

  deleteShip(ship_id){
    this.AdminApi.deleteShipUser(this.user_id, {'ship_id' : ship_id}).subscribe(response =>{
      if (response.status == 'success'){
        this.onChange.next(true);
        this.loadUserInfo()
      }
    })
  }

}
